import React from 'react';
import {connect} from 'react-redux';
import Preloader from "../common/Preloader/Preloader";
import {requestEducationDepart, setCurrentPage} from "../../redux/educational-reducer";
import {Container, Row} from "react-bootstrap";
import {NavLink, withRouter} from "react-router-dom";
import {compose} from "redux";
import EduWithDepart from "./EduWithDepart";

class EduWithDepartContainer extends React.Component {

    componentDidMount() {

        const {currentPage, pageSize} = this.props;
        this.props.requestEducationDepart(currentPage, pageSize,this.props.match.params.id);
        this.onPageChanged(1)
    }


    onPageChanged = (pageNumber) => {
        const {pageSize} = this.props;
        this.props.requestEducationDepart(pageNumber, pageSize,this.props.match.params.id);
    }
    render() {

        return <>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbItem'}>Учебно-методические пособия</div>
                            </NavLink>

                            <NavLink to={'/учебныеиздания/кафедры/'+this.props.institute.id}>
                                <div className={'breadcrumbItem'}>{this.props.institute.name.slice(0, 20)+'...'}</div>
                            </NavLink>
                            <div className={'breadcrumbItem'}>{this.props.department.name}</div>
                        </div>
                    </Row>
                </Container>
            </div>


            {this.props.isFetching ? <Preloader/> : null}



            <EduWithDepart
                totalCount={this.props.totalCount}
                pageSize={this.props.pageSize}
                currentPage={this.props.currentPage}
                onPageChanged={this.onPageChanged}
                articles={this.props.articles}
                department={this.props.department.name}
            />

        </>
    }
}

let mapStateToProps = (state) => {
    return {
        totalCount: state.educationalDatePage.totalCount,
        department: state.educationalDatePage.department,
        institute: state.educationalDatePage.institute,
        pageSize: state.educationalDatePage.pageSize,
        currentPage: state.educationalDatePage.currentPage,
        articles:  state.educationalDatePage.articles,
        isFetching: state.educationalDatePage.isFetching
    }
}

export default compose(
    connect(mapStateToProps, {setCurrentPage,requestEducationDepart}),
    withRouter
)(EduWithDepartContainer);
