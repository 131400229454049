import React from 'react';
import style from './ArticlesContent.module.css';
import ArticlesItem from "./ArticlesItem";
import Paginator from "../common/Paginator/Paginator";


const ArticlesContent = ({currentPage, totalArticlesCount, pageSize, onPageChanged, users, ...props}) => {



    return (
        <div>
            <div className={style.title}>
                Всего статей: {totalArticlesCount}
            </div>
            <Paginator currentPage={currentPage} onPageChanged={onPageChanged}
                       totalItemsCount={totalArticlesCount} pageSize={pageSize}/>
            <div className={style.articles}>

                <div>
                    {props.articles.length  ?
                        props.articles.map(u =>(

                                <ArticlesItem
                                    article={u}
                                    key={u.id}/>

                        )):null
                    }
                </div>
            </div>
        </div>
    )
}
export default ArticlesContent;