import {useEffect, useState} from 'react'
import React from "react";
import * as axios from "axios";
import {NavLink, Redirect} from "react-router-dom";
import style from "./../Article/form.module.css";
import {Button, Table} from "react-bootstrap";
import {baseURL} from "../../../api/api";
import CategoriesEducation from "./CategoriesEducation";


function AddCategoriesEducational() {

    const [dataAuthors, setDataAuthors] = useState([[], []]);
    const [dataKeywordsList, setDataKeywordsList] = useState([[], []]);

    const [name, setName] = useState("");

    useEffect(() => {
        getDataAuthors();
    }, []);
    useEffect(() => {
        if(name){
            setDataKeywordsList(dataAuthors?.filter((a)=> a?.name?.toLowerCase()?.includes(name?.toLowerCase()?.trim())))
        }else{
            setDataKeywordsList(dataAuthors)
        }

    }, [dataAuthors,name]);

    let token = localStorage.getItem('token');

    function getDataAuthors() {
        axios.get(baseURL+'api/educationalcategories')
            .then(response => {
                setDataAuthors(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }

    async function deleteOperation(id) {

        axios({
            method: 'DELETE',
            url: baseURL+"api/deletecategorieseducational/" + id,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            getDataAuthors();
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }
    async function updateOperation(id, name) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        axios({
            method: 'post',
            url: baseURL+'api/updatecategorieseducational'+"?_method=PUT",
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            getDataAuthors();
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function addAuthor() {
        if(dataAuthors.filter((a)=> a.name.trim().toLowerCase() === name.trim().toLowerCase()).length == 0 ){
        const formData = new FormData();
        formData.append('name', name);

        axios({
            method: 'post',
            url: baseURL+'api/addcategorieseducational',
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {

            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
            alert("Направление добавлено");

            window.location.reload();
        })
            .catch(function (error) {
                console.log(error);
            });

        }else{
            alert('Такой направление уже есть')
        }
    }

    return (
        <div>
            <div className={'container ' + style.form}>
                <h1 className="mt-4">Добавить автора</h1>

                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">

                    <div className="col-3">
                        <NavLink to={"/admin/alleducation"}><Button variant="dark">Список учебных издания</Button></NavLink>
                    </div>
                    <div className="col-3">
                        <NavLink to={"/admin/addeducation"}><Button variant="success">Добавить
                            учебное издание</Button></NavLink>
                    </div>
                    <div className="col-3"><NavLink to={"/admin/addauthoreducation"}><Button variant="secondary">Добавить автора
                        / список</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addcategorieseducation"}><Button variant="secondary">Добавить направление</Button></NavLink></div>
                </div>
                <div className="col-lg-12">
                    <hr/>
                    <label>Введите направление</label><br/>
                    <input type="text" className="form-control"
                           onChange={(e) => setName(e.target.value)}
                           placeholder="направление"/>
                    <button onClick={addAuthor} disabled={name?.length === 0} className="btn btn-primary">Добавить направление</button>
                    <hr/>
                    <Table>

                        <thead>
                        <tr>
                            <td>ID</td>
                            <td>NAME</td>
                            <td>UPDATE</td>
                            <td><span className="red">DELETE</span></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dataKeywordsList.map((item) =>
                                <CategoriesEducation key={item.id} item={item} updateOperation={updateOperation} deleteOperation={deleteOperation}/>
                            )
                        }
                        </tbody>
                    </Table>
                </div>

            </div>
        </div>
    )
}

export default AddCategoriesEducational