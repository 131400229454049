import React from 'react';
import style from './Section.module.css';
import {Col, Container, Row} from "react-bootstrap";
import Books from "./Books/Books";
import Articles from "./Articles/Articles";
import Disertation from "./Disertation/Disertation";

const Section = (props) =>{

    return (
        <div className={style.section}>
            <Container>
                <Row>
                    <div className="col-md-4  text-center"><Books count={props.props.edcount} /></div>
                    <div className="col-md-4  text-center"><Articles count={props.props.articlecount}/></div>
                    <div className="col-md-4  text-center"><Disertation count={props.props.disertationCount}/></div>
                </Row>
            </Container>
        </div>
    )
}
export default Section;