import Preloader from "components/common/Preloader/Preloader";
import Education from "components/Education/Education";
import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchBooksAsync, selectIprSmartReducer } from "redux/ipr-smart";
import { useDebounceValue } from "shared/hooks/useDebounceValue";
import { useUrlSearchParams } from "shared/hooks/useUrlSearchParams";
import Form from "react-bootstrap/Form";

export const IprSmart = () => {
    const dispatch = useDispatch();
    const { isFetching, books } = useSelector(selectIprSmartReducer);
    const [params, setParams] = useUrlSearchParams({ s: null, page: 1 });
    const debouncedSearch = useDebounceValue(params.s);
    const page = Number(params.page);

    const handlePageChanged = (pageNumber) => {
        setParams({ page: pageNumber });
    };

    useEffect(() => {
        dispatch(
            fetchBooksAsync({
                limit: 10,
                offset: 10 * (page - 1),
                title: debouncedSearch,
            })
        );
    }, [debouncedSearch, dispatch, page]);

    return (
        <>
            <div className="breadcrumdBottom">
                <Container>
                    <Row>
                        <div className="breadcrumdCont">
                            <NavLink to="/">
                                <div className="breadcrumbTitle">eLibrary</div>
                            </NavLink>
                            <div className="breadcrumbItem">Каталог "IPR SMART"</div>
                        </div>
                    </Row>
                </Container>
            </div>

            <div className="container mt-4">
                <div className="row">
                    <div className="col-12 col-md-4 mb-2" style={{ alignSelf: "stretch" }}>
                        <div
                            style={{
                                padding: "12px 10px",
                                background: "#f5f5f5",
                                borderRadius: 4,
                                height: "100%",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                    marginBottom: 12,
                                    lineHeight: "18px",
                                }}
                            >
                                Фильтр
                            </p>
                            <Form.Control
                                placeholder="Поиск"
                                value={params.s}
                                onChange={(e) => setParams({ s: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        {isFetching ? <Preloader /> : null}
                        <Education
                            totalCount={books.total}
                            pageSize={10}
                            currentPage={page}
                            onPageChanged={handlePageChanged}
                            articles={books.values}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
