import React, {useState} from 'react';

const OneJournal = ({item,deleteOperation, updateOperation}) => {
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState(item.name);

    return (
        <tr key={item.id}>
            <td>{item.id}</td>
            <td>
                {
                    !editMode ?
                        <span style={
                            {
                                border: '1px solid gray',
                                display:'inline-block',
                                padding: 4,
                                cursor:'pointer',
                                borderRadius: 10
                            }}>
                    {item.name}
                    </span>
                        :
                        <span className='d-flex justify-content-between'>
                                <input type="text" className='form-control' value={name} onChange={(e)=>{setName(e.target.value)}}/>
                                <button className='w-25 btn btn-outline-success' onClick={()=>{
                                    updateOperation(item.id, name)
                                    setEditMode(false)
                                }}>Сохранить
                                </button>
                            </span>

                }
            </td>
            <td>

            <button onClick={() => setEditMode(!editMode)}
                    className="btn btn-outline-secondary">Изменить </button>

            </td>
            <td>
                <button onClick={() => deleteOperation(item.id)}
                        className="btn btn-outline-danger">Удалить
                </button>
            </td>
        </tr>
    );
};

export default OneJournal;