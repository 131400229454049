import React from 'react';
import {connect} from 'react-redux';
import {requestArticles, setCurrentPage} from '../../redux/articles-reducer';
import ArticlesContent from './ArticlesContent';
import Preloader from "../common/Preloader/Preloader";

class ArticlesContentContainer extends React.Component {

    componentDidMount() {
        const {currentPage, pageSize} = this.props;
        this.props.requestArticles(currentPage, pageSize);
    }


    onPageChanged = (pageNumber) => {
        const {pageSize} = this.props;
        this.props.requestArticles(pageNumber, pageSize);
    }
    render() {

        return <>

            {this.props.isFetching ? <Preloader/> : null}



                 <ArticlesContent
                    totalArticlesCount={this.props.totalArticlesCount}
                    pageSize={this.props.pageSize}
                    currentPage={this.props.currentPage}
                    onPageChanged={this.onPageChanged}
                    articles={this.props.articles}
                />

        </>
    }
}

let mapStateToProps = (state) => {
    return {
        totalArticlesCount: state.articlesPage.totalArticlesCount,
        pageSize: state.articlesPage.pageSize,
        currentPage: state.articlesPage.currentPage,
        articles:  state.articlesPage.articles,
        isFetching: state.articlesPage.isFetching
    }
}


export default connect(mapStateToProps, {setCurrentPage,requestArticles})(ArticlesContentContainer)