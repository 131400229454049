import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./index.module.css";

export const Integration = () => {
    return (
        <div className={classes.root}>
            <Container>
                <Link to='/profspo'>Каталог "Профобразование"</Link>
                <br />
                <Link to='/iprsmart'>Каталог "IPR SMART"</Link>
            </Container>
        </div>
    );
};
