import React from 'react';

import style from './ArticlesItem.module.css';
import articlesPhoto from "../../assets/images/book.png";
import {NavLink} from "react-router-dom";
import {baseURL} from "../../api/api";


const ArticlesItem= (props) => {

    return (  <div  className={style.artItem}  >
                <div className='row pb-4'>
                    <div className="col-3">
                        {props.article?.release_id?.file_path

                            ?<img src={baseURL+props.article.release_id.file_path} alt=""/>
                            :<img src={articlesPhoto} alt=""/>

                        }
                    </div>
                    <div className="col-9">
                        <div className="text-secondary">Статья</div>
                        <NavLink to={'журнал/статья/'+props.article.id}><div className={style.title}>{props.article.name}</div></NavLink>
                        <div className='row mt-3'>
                            <div className="col-9">
                                <i className="fa fa-users" aria-hidden="true"></i> &nbsp;
                                {props.article.author.map(function (item) {
                                    return <span key={item.id+item.name} className={style.authorname}>

                                        <NavLink
                                        to={'/автор/'+item.id}>{item.name}&nbsp;</NavLink>
                                   </span>
                                }
                            )}
                            </div>
                            <div className="col-3">
                                udk: {props.article.udk}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}
export default ArticlesItem;