import React from 'react';
import {Container, Row} from "react-bootstrap";
import style from '../Section.module.css';
import {NavLink} from "react-router-dom";
const Books = (props) =>{
    return (
        <div >
            <Container>
                <Row>
                    <h4 className={style.title}><i className="fas fa-book"></i> Учебно-методические пособия <sup>{props.count}</sup></h4>
                    <ul>
                        <NavLink to={'/учебныеиздания'}><li>По дате публикации</li></NavLink>
                        <NavLink to={'/учебныеиздания/авторы'}><li>По авторам</li></NavLink>
                        <NavLink to={'/учебныеиздания/институты'}><li>По институтам</li></NavLink>
                    </ul>
                </Row>
            </Container>
        </div>
    )
}
export default Books;