import { profEducationApi } from "api/isu-api";
import { generateCreateAction } from "utils/createAction";

const initialState = {
    isFetching: true,
    books: {
        values: [],
        total: 0,
    },
};

// createAction возвращает action creator
const createAction = generateCreateAction("profEducation");

export const setIsFetching = createAction("setIsFetching");
export const setBooks = createAction("setBooks");

const _handleClick = async (params) => {
    try {
        const response = await profEducationApi.auth(params);
        const { data } = response.data;

        const a = Object.assign(document.createElement("a"), {
            href: data.link,
            target: "_blank",
            hidden: true,
        });
        document.body.append(a);
        a.click();
        a.remove();
    } catch {
        //pass
    }
};

const _transformResponse = (response) => {
    return {
        ...response,
        values: response.values.map((value) => {
            return {
                ...value,
                name: value.title,
                author: value.authors,
                date: value.created_at,
                image: value.cover,
                outsideImage: true,
                onClick: () => _handleClick({ url: `books/${value.id}` }),
            };
        }),
    };
};

export const fetchBooksAsync = (params) => {
    return async (dispatch) => {
        try {
            dispatch(setIsFetching(true));
            const { data } = await profEducationApi.getBooks(params);
            const { data: books } = data || {};
            if (books) {
                dispatch(setBooks(_transformResponse(books)));
            }
        } catch {
            //pass
        } finally {
            dispatch(setIsFetching(false));
        }
    };
};

export const profEducationReducer = (state = initialState, action) => {
    switch (action.type) {
        case setIsFetching.type:
            return { ...state, isFetching: action.payload };
        case setBooks.type:
            return { ...state, books: action.payload };
        default:
            return state;
    }
};

export const selectProfEducation = (state) => state.profEducation;
