import React from 'react';
import style from './Article.module.css';
import {Row} from "react-bootstrap";
import articlesPhoto from "./../../../assets/images/book.png";
import {NavLink} from "react-router-dom";
import {baseURL} from "../../../api/api";

const ArticleContent = (props) => {

    return (
        <div className={style.ArticleContent}>
            <div className={style.title}>
                <h1>{props.article.name}</h1>
            </div>
            <div className={style.article}>
                <Row >
                    <div className="col-4">
                        {props.article?.release_inf?.file_path
                            ? <img src={baseURL + props.article.release_inf.file_path} alt=""/>
                            : <img src={articlesPhoto} alt=""/>
                        }


                    </div>
                    <div className="col-8">
                        <div className={style.type}>Журнал</div>
                        <NavLink to={'/релизы/'+props.article?.release_inf?.journals_id}>
                            <div className={style.title}>{props.article?.journal?.name}</div>
                        </NavLink>
                        <div className={style.author}><i
                            className="fas fa-user-alt"></i> &nbsp;

                            {props.article.author.map(function (item,index) {

                                    return <span key={item.id}>

                                        <NavLink
                                            to={'/автор/' + item.id}>{item.name}&nbsp;</NavLink>
                                   </span>
                                }
                            )}
                        </div>
                        {/*<div className={style.date}>Март 2021</div>*/}
                        <div className="row mt-3">
                            <div className='col'><b>УДК:</b> {props.article.udk}</div>
                            <div className='col'><b>Номер:</b> {props.article.release_inf?.number}</div>

                        </div>
                        <div className="row mt-1 ">
                            <div className='col'><b>Том:</b> {props.article.release_inf?.tome}</div>
                            <div className='col'><b>Issn:</b> {props.article.release_inf?.issn}</div>
                        </div>
                        <div className={style.downloadBtn}>
                            <a className='text-white' target='_blank' href={baseURL + props.article.file_path}>
                                <button className='btn-primary w-100 mt-5 p-2 '>
                                    Скачать документ <i className="fas fa-file-pdf"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </Row>
            </div>
            <div className='mt-5'>
                <h4><i className="far fa-comment-dots"></i> Аннотация:</h4>
                <p>
                    {props.article.description}
                </p>
                <h4><i className="fas fa-key"></i> Ключевые слова:</h4>
                <div>{props.article.keywords.map(function (item) {
                        return <span key={item.id}>

                                        <NavLink className='text-secondary' to={'/хештег/'+item.id}>#{item.name}&nbsp;
                                        </NavLink>
                                   </span>
                    }
                )}</div>
                <h4 className='mt-3'>Прямая ссылка на файл статьи:</h4>
                <div>
                    <input className='form-control' onClick={(e) => e.target.select()} type="text"
                           value={baseURL + props.article.file_path}/>

                </div>
                {/*<h4>Поделиться:</h4>*/}
            </div>
        </div>
    )
}
export default ArticleContent;