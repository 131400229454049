import React from 'react';
import style from './Search.module.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {Field, reduxForm} from "redux-form";
import { withRouter} from "react-router-dom";
import icon from './../../../assets/images/main.png'

const SearchForm = (props) => {

    return (
        <form className={style.form} onSubmit={props.handleSubmit} >
            <div>
                <div className={style.area}>


                    <div className={style.button}>
                        <button type="submit" >
                            <span className={style.icon}>Искать</span>
                        </button>
                    </div>
                    <div className={style.input}>

                        <Field name="searchName" component="input" type="search" placeholder={'Поиск по названию'} value="search"/>

                    </div>


                </div>
            </div>
            <div>
                <div className="mt-2 p-0 text-md-left text-center" >
                    <label>
                        <Field name="sect" component="input" type="radio" value="articles" checked={true}/>
                        <span> по статьям</span>
                    </label>
                    <label className='ml-3'>
                        <Field  name="sect" component="input" type="radio" value="books" />
                        <span> по учебным изданиям</span>

                    </label>

                </div>
                <div className="col-4 mt-2 text-right">
                    {/*<NavLink className={style.extSearch} to={'/extSearch'}>Расширенный поиск</NavLink>*/}
                </div>
            </div>
        </form>
    )
}
const SearchReduxForm = reduxForm({
    form: 'search'
})(SearchForm);

const Search = (props) => {

    const onSubmit = (formData) =>{
        if (!formData.sect){formData.sect='articles'}
        props.history.push('/результатпоиска/'+formData.searchName+'/'+formData.sect);
    }

    return (
        <div className={style.search}>
            <Container>
                <Row>
                    <div className={style.searchRow}>
                        <div className={style.searchTitle}><img className={style.image} src={icon} alt=""/> <span className={style.library}>Library</span></div>
                        <SearchReduxForm onSubmit={onSubmit}/>
                    </div>


                </Row>
            </Container>
        </div>
    )
}

export default withRouter(Search);