import './App.css';
import Header from "./components/Header/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';

import {Route, Switch, useHistory, useLocation } from "react-router-dom";
import React from "react";
import Main from "./components/Main/Main";
import Footer from "./components/Footer/Footer";
import Articles from "./components/Articles/Articles";
import AddArticle from "./components/Admin/Article/AddArticle";
import {AllArticles} from "./components/Admin/Article/AllArticles";
import Login from "./components/Admin/Login";
import Admin from "./components/Admin/Admin";
import AddAuthors from "./components/Admin/Article/AddAuthors";
import AddKeywords from "./components/Admin/Article/AddKeyword";
import {AllJournals} from "./components/Admin/Journals/AllJournals";
import {AllReleases} from "./components/Admin/Journals/AllReleases";
import {AddReleases} from "./components/Admin/Journals/AddReleases";
import Search from "./components/Search/Search";
import ArticleContainer from "./components/Articles/Article/ArticleContainer";
import JournalsContainer from "./components/Journals/JournalsContainer";
import ReleasesContainer from "./components/Journals/ReleasesContainer";
import Institute from "./components/Education/Institute";
import Department from "./components/Education/Department";
import EduAuthors from "./components/Education/EduAuthors";
import EducationInfo from "./components/Education/EducationInfo";
import Protected from "./components/common/Protected/Protected";
import EditArticle from "./components/Admin/Article/EditArticle";
import EducationContainer from "./components/Education/EducationContainer";
import EduWithDepartContainer from "./components/Education/EduWithDepartContainer";
import EduOneAuthor from "./components/Education/EduOneAuthor";
import {AllEducational} from "./components/Admin/Educational/AllEducational";
import AddEducational from "./components/Admin/Educational/AddEducational";
import AddAuthorsEducational from "./components/Admin/Educational/AddAuthorsEducational";
import Pdf from "./components/PdfView/Pdf";
import EditEducational from "./components/Admin/Educational/EditEducational";
import EditReleases from "./components/Admin/Journals/EditReleases";
import AddCategoriesEducational from "./components/Admin/Educational/AddCategoriesEducational";
import getCookie from "./utils/customFunctions";
import {Worker} from "@react-pdf-viewer/core";
import { Profspo } from 'components/Profspo';
import { IprSmart } from 'components/IprSmart';
import { useEffect } from 'react';
import { AllDisertation } from 'components/Admin/Disertation/AllDisertation';
import AddAuthorsDisertation from 'components/Admin/Disertation/AddAuthorsDisertation';
import EditDisertation from 'components/Admin/Disertation/EditDisertation';
import AddDisertation from 'components/Admin/Disertation/AddDisertation';
import AddNapravlenieDisertation from 'components/Admin/Disertation/AddNapravlenieDisertation';
import { AddSpecDisertation } from 'components/Admin/Disertation/AddSpecDisertation';
import DisertationAuthors from 'components/Disertation/DisertationAuthors';
import Napravlenie from 'components/Disertation/Napravlenie';
import Spec from 'components/Disertation/Spec';
import DisertationsContainer from 'components/Disertation/DisertationsContainer';
import Disertation from 'components/Disertation/Disertation';
import DisertationOneAuthor from 'components/Disertation/DisertationOneAuthor';



function App() {

    let location = useLocation();
    let history = useHistory();
    useEffect(()=>{

        if(!getCookie('token') &&
            !localStorage.getItem('token') &&
            !history.location.pathname.toLowerCase().includes('admin')
        ){
            alert('Пожалуйста, пройдите авторизацию')
            window.location = 'http://isu.gstou.ru/'
        }


    },[])

    useEffect(() => {
       window.scrollTo({left:0, top: 0, behavior: 'smooth'})
    }, [location.pathname])
    

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/legacy/build/pdf.worker.js">

        <div className="App">
            <Header/>
            {/*<Breadcrumd/>*/}
            <div className="body-section">
                <Switch>
                    <Route exact path='/'
                           render={() => <Main/>}/>
                    <Route path='/результатпоиска/:search/:sect'
                           render={() => <Search/>}/>
                    <Route path='/журнал/статья/:id?'
                           render={() => <ArticleContainer/>}/>
                    <Route path='/журналы'
                           render={() => <JournalsContainer/>}/>
                    <Route path='/релизы/:id?'
                           render={() => <ReleasesContainer/>}/>
                    <Route path='/документ/:id?/:name/:isDisertation?'
                           render={() => <Pdf />}/>


                    <Route path='/статьи'
                           render={() => <Articles page={'article'}/>}/>
                    <Route path='/авторы'
                           render={() => <Articles page={'author'}/>}/>
                    <Route path='/ключевыеслова'
                           render={() => <Articles page={'keyword'}/>}/>
                    <Route path='/автор/:id?'
                           render={() => <Articles page={'authorid'}/>}/>
                    <Route path='/хештег/:id?'
                           render={() => <Articles page={'keywordid'}/>}/>


                    {/*Учебно-методические пособия*/}

                    <Route path='/учебныеиздания/авторы'
                           render={() => <EduAuthors/>}/>
                    <Route path='/учебныеиздания/автор/:id'
                           render={() => <EduOneAuthor/>}/>
                    <Route path='/учебныеиздания/институты'
                           render={() => <Institute/>}/>
                    <Route path='/учебныеиздания/кафедры/:id'
                           render={() => <Department/>}/>
                    <Route path='/учебныеиздания/кафедра/:id'
                           render={() => <EduWithDepartContainer/>}/>
                    <Route path='/учебныеиздания/учебник/:id'
                           render={() => <EducationInfo/>}/>
                    <Route path='/учебныеиздания/'
                           render={() => <EducationContainer/>}/>

                     
                     {/*Диссертации*/}
                     <Route path='/диссертации/авторы'
                           render={() => <DisertationAuthors/>}/>
                     <Route path='/диссертации/автор/:id'
                           render={() => <DisertationOneAuthor/>}/>
                     <Route path='/диссертации/направления'
                            render={() => <Napravlenie/>}/>
                     <Route path='/диссертации/специальность/:id'
                            render={() => <Spec/>}/>
                     <Route path='/диссертация/:id'
                             render={() => <Disertation/>}/>
                     <Route path='/диссертации/:id'
                             render={() => <DisertationsContainer/>}/>

                     {/*Профобразование */}
                    <Route path='/profspo'
                           render={() => <Profspo/>}/>

                     {/*IprSmart */}
                    <Route path='/iprsmart'
                           render={() => <IprSmart/>}/>


                    <Route path='/admin/login'
                           render={() => <Login/>}/>
                    <Route path='/admin/addarticle'
                           render={() => <Protected Cmp={AddArticle}/>}/>
                    <Route path='/admin/editarticle/:id'
                           render={() => <Protected Cmp={EditArticle}/>}/>
                    <Route path='/admin/allarticles'
                           render={() => <Protected Cmp={AllArticles}/>}/>
                    <Route path='/admin/addauthors'
                           render={() => <Protected Cmp={AddAuthors}/>}/>
                    <Route path='/admin/addkeywords'
                           render={() => <Protected Cmp={AddKeywords}/>}/>
                    <Route path='/admin/alljournals'
                           render={() => <Protected Cmp={AllJournals}/>}/>
                    <Route path='/admin/allreleases'
                           render={() => <Protected Cmp={AllReleases}/>}/>
                    <Route path='/admin/addreleases'
                           render={() => <Protected Cmp={AddReleases}/>}/>
                    <Route path='/admin/editreleases/:id'
                           render={() => <Protected Cmp={EditReleases}/>}/>
                    <Route path='/admin/alleducation'
                           render={() => <Protected Cmp={AllEducational}/>}/>categories
                    <Route path='/admin/addauthoreducation'
                           render={() => <Protected Cmp={AddAuthorsEducational}/>}/>
                    <Route path='/admin/addcategorieseducation'
                           render={() => <Protected Cmp={AddCategoriesEducational}/>}/>
                    <Route path='/admin/addeducation'
                           render={() => <Protected Cmp={AddEducational}/>}/>
                    <Route path='/admin/editeducational/:id'
                           render={() => <Protected Cmp={EditEducational}/>}/>

                     <Route path='/admin/editdisertation/:id'
                           render={() => <Protected Cmp={EditDisertation}/>}/>
                     <Route path='/admin/alldisertation'
                           render={() => <Protected Cmp={AllDisertation}/>}/>
                     <Route path='/admin/addauthordisertation'
                           render={() => <Protected Cmp={AddAuthorsDisertation}/>}/>
                     <Route path='/admin/addnapravleniedisertation'
                           render={() => <Protected Cmp={AddNapravlenieDisertation}/>}/>
                     <Route path='/admin/addspecdisertation'
                            render={() => <Protected Cmp={AddSpecDisertation}/>}/>
                     <Route path='/admin/adddisertation'
                           render={() => <Protected Cmp={AddDisertation}/>}/>
                     <Route path='/admin/editeducational/:id'
                           render={() => <Protected Cmp={EditEducational}/>}/>

                    <Route path='/admin'
                           render={() => <Protected Cmp={Admin}/>}/>


                    <Route path='*'
                           render={() => <div>404 Страница не найдена</div>}/>


                </Switch>
            </div>
            <Footer/>
        </div>
        </Worker>
    );
}

export default App;
