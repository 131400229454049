import React from 'react';
import './Sample.css';
import {NavLink, withRouter} from "react-router-dom";
import {baseURL} from "../../api/api";
import {Container, Row} from "react-bootstrap";
import {Viewer} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Pdf = (props) => {
    const url = props.match.params?.isDisertation === 'isDisertation' ?
    `${baseURL + 'api/file/ed/' + props.match.params.id}` : 
    `${baseURL + 'api/file/ed/' + props.match.params.id}`

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>
                          
                            <div className={'breadcrumbItem'}>
                                {props.match.params?.isDisertation === 'isDisertation' ? 'Диссертация' : 'Учебно-методические пособия' }
                            </div>
                            <div className={'breadcrumbItem'}>{props.match.params.name}</div>

                        </div>
                    </Row>
                </Container>
            </div>
            <div className='container text-center'>
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '1050px',
                        }}
                    >
                        <Viewer fileUrl={url} initialPage={1} plugins={[defaultLayoutPluginInstance]}/>
                    </div>

            </div>
        </>
);
}
export default withRouter(Pdf);