import React, {useEffect} from 'react';
import style from './Login.module.css';
import {NavLink, Redirect} from "react-router-dom";
import * as axios from "axios";
import {baseURL} from "../../api/api";
import checkToken from "../common/Protected/CheckToken";


const Admin = () => {
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }
    useEffect(()=>{
        checkToken();
    },[])



    let user = localStorage.getItem('user-info');
    return (
        <div className={style.admin}>
            <div className="container">
                <h1 className="mt-1 mb-5">Главная страница панели администратора <span className="red">{user}</span> </h1>
                <div className="justify-content-center d-flex mb-3 pb-3"  style={{borderBottom: '1px solid gray', gap: 10}}>
                    <div >
                        <NavLink to={"/admin/allArticles"}>
                            <button className="btn btn-dark">Статьи</button>
                        </NavLink>
                    </div>
                    <div >
                        <NavLink to={"/admin/alljournals"}>
                            <button className="btn btn-dark">Журналы</button>
                        </NavLink>
                    </div>
                </div>
                <div className="justify-content-center d-flex  mb-3 pb-3" style={{borderBottom: '1px solid gray'}}>
                     <div>
                        <NavLink to={"/admin/alleducation"}>
                            <button className="btn btn-dark">Учебно-методические пособия    </button>
                        </NavLink>
                    </div>
                </div>
                <div className="justify-content-center d-flex  mb-3 pb-3" style={{borderBottom: '1px solid gray'}}>
                     <div>
                        <NavLink to={"/admin/alldisertation"}>
                            <button className="btn btn-dark">Диссертации</button>
                        </NavLink>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <div>
                        <button className="btn btn-danger" onClick={() => logout()}>Выйти из учетной записси</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Admin;