import * as axios from "axios";
import {baseURL} from "../../../api/api";

function checkToken() {
    axios({
        method: 'GET',
        url: baseURL+'api/user',
        headers: {
            'Authorization': "Bearer " + localStorage.getItem('token')
        }
    })
        .then(response => {
            console.log('token valid')
        })
        .catch(error => {
            localStorage.clear();
            window.location.reload();
        });


}
export default checkToken