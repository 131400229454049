import axios from "axios";
import getCookie from "utils/customFunctions";

const ISU_API_URL = "https://backend-isu.gstou.ru/api/";

const isuApi = axios.create({
    baseURL: ISU_API_URL,
    headers: {
        Authorization: `Bearer ${getCookie("token")}`,
    },
});

export const profEducationApi = {
    getBooks: (params) => isuApi.get("elib/profspo/books/", { params }),
    auth: (params) => isuApi.get("elib/profspo/auth/", { params }),
};

export const iprSmartApi = {
    getBooks: (params) => isuApi.get("elib/smart/books/", { params }),
    auth: (params) => isuApi.get("elib/smart/auth/", { params }),
};
