import {NavLink, withRouter} from "react-router-dom";
import {Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {baseURL} from "../../api/api";
import * as axios from "axios";


const Disertation = (props) => {

    const [data, setdata] = useState({});
    useEffect(() => {
        getsetDepartmentName();
    }, []);

    function getsetDepartmentName() {

        axios.get(baseURL + 'api/onedisertation/' + props.match.params.id)
            .then(response => {
                setdata(response.data.items);
            })
            .catch(error => console.error(error))
    }

    let file = data.file_path ? data.file_path?.slice(12) : null;
    return (
        <>

            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>


                            <NavLink to={'/диссертации/направления'}>
                                <div className={'breadcrumbItem'}>Диссертации</div>
                            </NavLink>


                            <div className={'breadcrumbItem'}>{data?.name}</div>


                        </div>
                    </Row>
                </Container>
            </div>
            <div className='container mt-4'>

                <div className='row'>
                    <div className="col-12">
                        <h2>{data?.name}</h2>
                        <hr/>
                        <p className='text-secondary'>Диссертация </p>

                        <p><i className="fas fa-user-alt"></i>
                            {Array.isArray(data.author) ?
                                data.author.map((item, index) => {
                                    if (index === (data.author.length - 1)) {
                                        return <span key={item.id}><NavLink
                                            to={'/диссертации/автор/' + item.id}><span> {item.name}</span></NavLink></span>
                                    }
                                    return <span key={item.id}><NavLink
                                        to={'/диссертации/автор/' + item.id}><span> {item.name}</span></NavLink>, </span>
                                })
                                : null

                            }

                        </p>
                        <p>
                            <b>Направление:</b> {data?.spec}
                        </p>
                        <p>
                            <b>Специальность:</b> {data?.napravlenie}
                        </p>
                        <p>
                            <b>Страниц:</b> {data?.pages}
                        </p>
                            <NavLink to={'/документ/' + file + '/' + data.name + '/isDisertation'} >
                                <button className='btn-primary btn w-25'>Посмотреть <i className="far fa-file-pdf"></i>
                                </button>
                            </NavLink>
                        <hr/>
                        <h4><i className="far fa-comment-dots"></i> Оглавление диссертации</h4>
                        <div class="markdown">
                             <span
                            dangerouslySetInnerHTML={{
                                __html: data.description
                            }}
                            />
                        </div>
                        <h4><i className="fas fa-info-circle"></i> Заключение диссертации</h4>
                        <div  class="markdown">
                             <span
                            dangerouslySetInnerHTML={{
                                __html: data.endDescription
                            }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default withRouter(Disertation);