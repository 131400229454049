import React from 'react';
import {connect} from 'react-redux';
import Preloader from "../common/Preloader/Preloader";
import {requestDisertations, setCurrentPage} from "../../redux/disertation-reducer";
import {Container, Row} from "react-bootstrap";
import {NavLink, withRouter} from "react-router-dom";
import {compose} from "redux";
import Disertations from './Disertations';

class DisertationsContainer extends React.Component {

    componentDidMount() {
        const {currentPage, pageSize} = this.props;
        this.props.requestDisertations(currentPage, pageSize,this.props.match.params.id);
        this.onPageChanged(1)
    }


    onPageChanged = (pageNumber) => {
        const {pageSize} = this.props;
        this.props.requestDisertations(pageNumber, pageSize,this.props.match.params.id);
    }
    
    render() {

        return <>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>
                            <div className={'breadcrumbItem'}>Диссертации</div>
                                <div className={'breadcrumbItem'}>
                                    {this.props.napravlenie}
                                </div>
                            <div className={'breadcrumbItem'}>{this.props.spec}</div>
                        </div>
                    </Row>
                </Container>
            </div>


            {this.props.isFetching ? <Preloader/> : null}



            <Disertations
                totalCount={this.props.totalCount}
                pageSize={this.props.pageSize}
                currentPage={this.props.currentPage}
                onPageChanged={this.onPageChanged}
                articles={this.props.articles}
                spec={this.props.spec}
                napravlenie={this.props.napravlenie}
            />

        </>
    }
}

let mapStateToProps = (state) => {
    return {
        totalCount: state.disertation.totalCount,
        pageSize: state.disertation.pageSize,
        currentPage: state.disertation.currentPage,
        articles:  state.disertation.disertations,
        isFetching: state.disertation.isFetching,
        napravlenie: state.disertation.napravlenie,
        spec: state.disertation.spec
    }
}

export default compose(
    connect(mapStateToProps, {setCurrentPage,requestDisertations}),
    withRouter
)(DisertationsContainer);
