import {useEffect, useState} from 'react'
import React from "react";
import * as axios from "axios";
import {NavLink, Redirect} from "react-router-dom";
import style from "./form.module.css";
import {Button, Table} from "react-bootstrap";
import {baseURL} from "../../../api/api";


function AddAuthors() {

    const [dataAuthors, setDataAuthors] = useState([[], []]);
    const [dataAuthorsList, setDataAuthorsList] = useState([[], []]);
    const [name, setName] = useState("");

    useEffect(() => {
        getDataAuthors();
    }, []);

    useEffect(() => {
        if(name){
            setDataAuthorsList(dataAuthors?.filter((a)=> a?.name?.toLowerCase()?.includes(name?.toLowerCase()?.trim())))
        }else{
            setDataAuthorsList(dataAuthors)
        }

    }, [dataAuthors,name]);
    let token = localStorage.getItem('token');

    function getDataAuthors() {
        axios.get(baseURL+'api/authors')
            .then(response => {
                setDataAuthors(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }

    async function deleteOperation(id) {

        axios({
            method: 'DELETE',
            url: baseURL+"api/authordelete/" + id,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            getDataAuthors();
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function addAuthor() {
        if(dataAuthors.filter((a)=> a?.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase())?.length == 0 ){
            const formData = new FormData();
            formData.append('name', name.trim());

            axios({
                method: 'post',
                url: baseURL+'api/authoradd',
                data: formData,
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then(function (response) {

                console.log('Ответ сервера успешно получен!');
                console.log(response.data);
                alert("Автор добавлен");

                window.location.reload();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            alert('Такой автор уже есть')
        }



    }

    return (
        <div>
            <div className={'container ' + style.form}>
                <h1 className="mt-4">Добавить автора</h1>

                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">


                    <div className="col-2">
                        <NavLink to={"/admin/allArticles"}><Button variant="dark">Список статей</Button></NavLink>
                    </div>
                    <div className="col-2">
                        <NavLink to={"/admin/addArticle"}><Button variant="success">Добавить
                            статью</Button></NavLink>
                    </div>
                    <div className="col-4"><NavLink to={"/admin/addAuthors"}><Button variant="secondary">Добавить автора
                        / список</Button></NavLink></div>
                    <div className="col-4"><NavLink to={"/admin/addKeywords"}><Button variant="secondary">Добавить ключевые слова (хештэг)</Button></NavLink></div>
                </div>
                <div className="col-lg-12">
                    <hr/>
                    <label>Введите нового автора</label><br/>
                    <input type="text" className="form-control"
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                           placeholder="Автор"/>
                    <button onClick={addAuthor} disabled={name?.length === 0} className="btn btn-primary">Добавить автора</button>
                    <hr/>
                    <Table>

                        <thead>
                        <tr>
                            <td>ID</td>
                            <td>NAME</td>
                            <td><span className="red">DELETE</span></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dataAuthorsList.map((item) =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>

                                    <td>
                                        <button
                                            onClick={() => deleteOperation(item.id)}
                                            className="btn btn-outline-danger">Delete
                                        </button>
                                    </td>

                                </tr>
                            )

                        }
                        </tbody>
                    </Table>
                </div>

            </div>
        </div>
    )
}

export default AddAuthors