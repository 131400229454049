import React from 'react';
import {Container, Row} from "react-bootstrap";
import style from './ArticlesSidebar.module.css';
import {NavLink} from "react-router-dom";

const ArticlesSidebar= (props) => {

    let a,b,c;
    if (props.page=='author'){
        b = style.current
    }else if(props.page=='article'){
        a = style.current
    }else if(props.page=='keyword'){
        c = style.current
    }

    return (
        <div className={style.cont}>
            <div className={style.title}>
                <h1>Статьи</h1>
            </div>
            <div className={style.nav}>
                <ul>
                    <li className={a}><NavLink to={'/статьи'}><p>По дате публикации</p></NavLink></li>
                    <li className={b}><NavLink to={"/авторы"}><p>По авторам</p></NavLink></li>
                    <li className={c}><NavLink to={"/ключевыеслова"}><p>По ключевым словам</p></NavLink></li>
                </ul>
            </div>
        </div>
    )
}
export default ArticlesSidebar;