import { iprSmartApi } from "api/isu-api";
import { generateCreateAction } from "utils/createAction";

const initialState = {
    isFetching: true,
    books: {
        values: [],
        total: 0,
    },
};

const createAction = generateCreateAction("iprSmart");

export const setIsFetching = createAction("setIsFetching");
export const setBooks = createAction("setBooks");

const _handleClick = async (params) => {
    try {
        const response = await iprSmartApi.auth(params);
        const { data } = response.data;

        const a = Object.assign(document.createElement("a"), {
            href: data,
            target: "_blank",
            hidden: true,
        });
        document.body.append(a);
        a.click();
        a.remove();
    } catch {
        //pass
    }
};

const _transformResponse = (response) => {
    return {
        ...response,
        values: response.values.map((value) => {
            return {
                ...value,
                name: value.title,
                author: value.authors,
                date: value.pubyear.toString(),
                image: value.image,
                outsideImage: true,
                onClick: () => _handleClick({ publication_id: value.id }),
            };
        }),
    };
};

export const fetchBooksAsync = (params) => {
    return async (dispatch) => {
        try {
            dispatch(setIsFetching(true));
            const { data } = await iprSmartApi.getBooks(params);
            const { data: books, total } = data || {};
            if (books) {
                dispatch(
                    setBooks(
                        _transformResponse({
                            total,
                            values: books,
                        })
                    )
                );
            }
        } catch {
            //pass
        } finally {
            dispatch(setIsFetching(false));
        }
    };
};

export const iprSmartReducer = (state = initialState, action) => {
    switch (action.type) {
        case setIsFetching.type:
            return { ...state, isFetching: action.payload };
        case setBooks.type:
            return { ...state, books: action.payload };
        default:
            return state;
    }
};

export const selectIprSmartReducer= (state) => state.iprSmart;
