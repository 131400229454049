import React from 'react';
import {NavLink} from "react-router-dom";


const Journals = (props) => {

    console.log(props)


    return (
        <div className='container'>
            <h1 className='mt-5'>Журналы</h1>
            <div className="row mt-5 mb-5">
                {props?.journals?.items && props.journals.items.map((item) => {
                    return (
                    <div className="col-12 mb-3">
                        <div className="card">
                            <div className="card-body d-flex justify-content-between">

                                <h5 className="card-title">Журнал {item.name}</h5>
                                <NavLink to={"/релизы/"+item.id} className="btn btn-dark text-white ">Подробнее</NavLink>
                            </div>
                        </div>
                    </div>
                    )
                })}
                {/*<div className="col-12 mb-3">*/}
                {/*    <div className="card">*/}
                {/*        <div className="card-body">*/}

                {/*            <h5 className="card-title">Журнал «Вестник ГГНТУ. Гуманитарные и социально-экономические науки»</h5>*/}
                {/*            <p className="card-text">Издание основано в ФГБОУ ВО «Грозненский государственный нефтяной технический университет имени академика М.Д. Миллионщикова» в 2001 году.*/}

                {/*                Журнал является рецензируемым изданием с периодичностью выпуска 4 раза в год. В отличие от сборников статей наше издание имеет постоянное количество страниц, формат и даже вес, поэтому количество статей в номере определяется заданным объёмом.</p>*/}
                {/*            <NavLink to={"/релизы/2"} className="btn btn-dark text-white">Подробнее</NavLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-12">*/}
                {/*    <div className="card">*/}
                {/*        <div className="card-body">*/}
                {/*            <h5 className="card-title">Журнал «Вестник ГГНТУ. Технические науки»</h5>*/}
                {/*            <p className="card-text">Издание основано в ФГБОУ ВО «Грозненский государственный нефтяной технический университет имени академика М.Д. Миллионщикова» в 2001 году.*/}

                {/*                Журнал является рецензируемым изданием с периодичностью выпуска 4 раза в год. В отличие от сборников статей наше издание имеет постоянное количество страниц, формат и даже вес, поэтому количество статей в номере определяется заданным объёмом.</p>*/}
                {/*            <NavLink to={"/релизы/1"} className="btn btn-dark text-white">Подробнее</NavLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Journals