import {articlesAPI} from "../api/api";

const SET_ARTICLES = 'SET_ARTICLES';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_TOTAL_ARTICLES_COUNT = 'SET_TOTAL_ARTICLES_COUNT';



let initialState = {
    articles: [],
    isFetching: true,
    pageSize: 5,
    totalArticlesCount: 0,
    currentPage: 1
};

const articlesReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_ARTICLES: {

            return {...state, articles: action.articles}

        }
        case SET_CURRENT_PAGE: {
            return {...state, currentPage: action.currentPage}
        }
        case SET_TOTAL_ARTICLES_COUNT: {
            return {...state, totalArticlesCount: action.count}
        }
        case TOGGLE_IS_FETCHING: {
            return {...state, isFetching: action.isFetching}
        }

        default:
            return state;
    }
}



export const setArticles = (articles) => ({type: SET_ARTICLES, articles});
export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, isFetching});
export const setCurrentPage = (currentPage) => ({type: SET_CURRENT_PAGE, currentPage})
export const setTotalArticlesCount = (totalArticlesCount) => ({type: SET_TOTAL_ARTICLES_COUNT, count: totalArticlesCount})


export const requestArticles = (page, pageSize) => {

    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        dispatch(setCurrentPage(page))
        let data = await articlesAPI.getArticles(page, pageSize);
        dispatch(toggleIsFetching(false));
        dispatch(setArticles(data.items));
        dispatch(setTotalArticlesCount(data.totalCount));

    }
}



export default articlesReducer


