import style from "./Education.module.css";
import articlesPhoto from "../../assets/images/book.png";
import { NavLink } from "react-router-dom";
import { baseURL } from "../../api/api";

const EducationItem = (props) => {
    const title_additional = props.article.title_additional || "Учебно-методические пособия";

    const onClick = props.article.onClick;
    const NavComponent = onClick ? "span" : NavLink;
    const navProps = onClick
        ? {
              onClick,
              style: {
                  cursor: "pointer",
              },
          }
        : { to: "/учебныеиздания/учебник/" + props.article.id };

    return (
        <div className={style.artItem}>
            <div className="row pb-4">
                <div className="col-2">
                    {props.article.image ? (
                        <img
                            src={
                                props.article.outsideImage
                                    ? props.article.image
                                    : baseURL + props.article.image
                            }
                            alt=""
                        />
                    ) : (
                        <img src={articlesPhoto} alt="" />
                    )}
                </div>
                <div className="col-10">
                    <div className="text-secondary">{title_additional}</div>
                    <NavComponent {...navProps}>
                        <div className={style.Edutitle}>
                            {props.article?.name ? props.article.name : null}
                        </div>
                    </NavComponent>
                    <div className="row mt-3">
                        <div className="col-6">Опубликовано: {props.article.date ? props.article.date.slice(0, 4) : null}</div>
                        <div className="col-6">
                            Автор:{" "}
                            {typeof props.article.author === "string"
                                ? props.article.author
                                : props.article.author.length
                                ? props.article.author.map((item) => (
                                      <span key={item.id}>{item.name + ",\n"}</span>
                                  ))
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EducationItem;
