import React from 'react';
import {connect} from 'react-redux';
import Preloader from "../common/Preloader/Preloader";
import {compose} from "redux";
import {NavLink, withRouter} from "react-router-dom";
import {requestReleases} from "../../redux/releases-reducer";
import Releases from "./Releases";
import {Container, Row} from "react-bootstrap";


class ReleasesContainer extends React.Component {


    refreshProfile() {
        let id = this.props.match.params.id;
        if (!id) {
            id = 1;
        }
        this.props.requestReleases(id);
    }

    componentDidMount() {
        this.refreshProfile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id != prevProps.match.params.id ) {
            this.refreshProfile();
        }
    }



    render() {
        return <>
            <div className={'breadcrumdBottom'}>
                <Container className='p-0'>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>

                            <NavLink to={'/журналы'}>
                                <div className={'breadcrumbItem'}>Журналы</div>
                            </NavLink>

                                <div className={'breadcrumbItem'}>Выпуски журнала</div>

                        </div>
                    </Row>
                </Container>
            </div>
            <div>{this.props.isFetching ? <Preloader/> : null}</div>
            <Releases
                releases={this.props.releases}
            />

        </>
    }
}

let mapStateToProps = (state) => {
    return {
        releases:  state.releasesPage.releases,
        isFetching: state.releasesPage.isFetching
    }
}


// export default connect(mapStateToProps, {requestArticle})(ArticleContainer)
export default  compose(connect(mapStateToProps, {requestReleases}), withRouter)(ReleasesContainer)