import React, {useEffect, useState} from 'react';
import * as axios from "axios";
import {NavLink} from "react-router-dom";
import articlesPhoto from "../../../assets/images/book.png";
import {baseURL} from "../../../api/api";

const Author = (props) => {

    const [articles, setarticles] = useState([[], []]);
    const [count, setcount] = useState('');
    const [name, setname] = useState('');

    useEffect(() => {
        getAuthorArticle();
        getAuthorName();
    }, []);

    function getAuthorArticle() {
        axios.get(baseURL+'api/articleforauthor/' + props.id)
            .then(response => {


                setarticles(response.data.article);
                setcount(response.data.count);
            })
            .catch(error => console.error(error));
    }

    function getAuthorName() {
        axios.get(baseURL+'api/getauthor/' + props.id)
            .then(response => {
                setname(response.data.item.shift().name);
            })
            .catch(error => console.error(error))
    }


    return (
        <div>
            <div><h1>Автор <span className="red">{name}</span></h1></div>
            <div><h4>Количество статей: <span className="red">{count}</span></h4></div>
            <div>
                {articles.length ?
                    articles.map(function (item) {
                    return <div key={item.id}>
                        <div className="row">
                            <div className="col-2">
                                <img src={articlesPhoto} alt=""/>
                            </div>
                            <div className="col-10">
                                <div className='row mb-3'>
                                    <div className="col-12 text-secondary">Статья</div>
                                    <div className='col-12'><NavLink to={'/журнал/статья/'+item.id}>{item.name}</NavLink></div>
                                </div>
                                <div className='row'>
                                    <div className="col-9">


                                    </div>
                                    <div className="col-3">
                                        udk: {item.udk}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                }):null
                }
            </div>

        </div>
    )
}

export default Author;