import React from 'react';
import style from './Article.module.css';
import {NavLink} from "react-router-dom";

const ArticleSidebar = (props) => {

    return (
        <div className={style.ArticleSidebar}>
            <div className={style.title}>
                <h4>Статьи в номере</h4>
            </div>
            <div className={style.nav}>
                <ul>
                    {props.release.map( item=>
                        <li className={props.id==item.id?style.current:null }
                        >
                            <NavLink to={'/журнал/статья/'+item.id}><p><i className="far fa-file-alt"></i> {item.name}</p></NavLink>
                        </li>

                    )}

                </ul>
            </div>

        </div>
    )
}
export default ArticleSidebar;