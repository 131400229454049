import React, {useEffect, useState} from 'react';
import * as axios from "axios";
import {NavLink} from "react-router-dom";
import {baseURL} from "../../api/api";

const ArticlesKeyword = () => {

    const [author, setauthor] = useState([]);
    const [authorsList, setAuthorsList] = useState([]);
    const [name, setName] = useState('')

    useEffect(() => {
        getAuthorName();
    }, []);

    useEffect(() => {
        if(name){
            setAuthorsList(author.filter(item=> item.name.toUpperCase().includes(name.toUpperCase())))
        }else{
            setAuthorsList(author);
        }
    }, [name,author]);

    function getAuthorName() {
        axios.get(baseURL+'api/keywords')
            .then(response => {
                setauthor(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error))
    }

    return (
        <div>
            <div className="row">
                <h1>По ключевым словам</h1>
                <div className='w-100'>
                    <ul className="row">
                        <input
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                            placeholder='Поиск автора'
                            type="text"
                            className='form-control mb-3 ml-3'/>
                        {authorsList ?
                            authorsList.map(function (item) {

                            return (
                                <li key={item.id} className="col-6" ><NavLink to={"/хештег/"+item.id}>#{item.name}</NavLink> </li>
                            )


                        }):null}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ArticlesKeyword;