import style from './Education.module.css';
import {NavLink} from "react-router-dom";
import EducationItem from "./EducationItem";
import React from "react";
import Paginator from "../common/Paginator/Paginator";



const EduWithDepart = (props) => {
    return (
        <div className={style.contInst}>


            <div className='container mt-4'>

                <div className='row'>
                    <div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h1>Статьи</h1>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li ><NavLink to={'/учебныеиздания'}><p>По дате публикации</p></NavLink></li>
                                    <li ><NavLink to={"/учебныеиздания/авторы"}><p>По авторам</p></NavLink></li>
                                    <li className={style.current}><NavLink to={"/учебныеиздания/институты"}><p>По институтам</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <h2>Учебно-методические пособия кафедры - <span className='red'>{props.department}</span></h2>
                        <hr/>
                        <h3 className='mb-4'>Всего учебно-методических пособий на кафедре: {props.totalCount} </h3>
                        <Paginator currentPage={props.currentPage} onPageChanged={props.onPageChanged}
                                   totalItemsCount={props.totalCount} pageSize={props.pageSize}/>
                        <div>
                            {props.articles.length  ?
                                props.articles.map(u =>(

                                    <EducationItem
                                        article={u}
                                        key={u.id}/>

                                )):null
                            }
                        </div>


                    </div>
                </div>

            </div>
        </div>
    )
}
export default EduWithDepart;