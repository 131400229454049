import React from 'react';
import preloader from "../../../assets/images/preloader.svg";
import style from './Preloader.module.css';

let Preloader = (props) => {
    return <div className={style.cont}  >
        <img src={preloader} className={style.svg} alt="preloader"/>
    </div>
}

export default Preloader;