import React from 'react';
import style from './Statistics.module.css';
import {Container, Row} from "react-bootstrap";

const Statistics= (props) => {
    return (
        <div className={style.facts}>
            <Container>
                <Row>
                    <div className={style.title}>Поиск среди <span className="red">{props.props.articlecount} статей</span> и <span className="red">{props.props.edcount} учебно-методических пособий</span></div>

                </Row>
                <Row>

                    <div className={"d-flex justify-content-between w-100"}>

                        <div className="">
                            <div className={style.factsWrap}>
                                <div className={style.graph}>
                                    <div className={style.graphLeftSide}>
                                        <div className={style.graphLeftContainer}>
                                            <div className={style.graphLeftHalf}></div>
                                        </div>
                                    </div>
                                    <div className={style.graphRightSide}>
                                        <div className={style.graphRightContainer}>
                                            <div className={style.graphRightHalf}></div>
                                        </div>
                                    </div>
                                    <i className="fas fa-book"></i>
                                    <div className={style.factsWrapNum}>
                                        <span className={style.counter}>{props.props.edcount}</span>
                                    </div>
                                </div>
                                <h6>Учебно-методические пособия</h6>
                            </div>
                        </div>
                        <div className="">
                            <div className={style.factsWrap}>
                                <div className={style.graph}>
                                    <div className={style.graphLeftSide}>
                                        <div className={style.graphLeftContainer}>
                                            <div className={style.graphLeftHalf}></div>
                                        </div>
                                    </div>
                                    <div className={style.graphRightSide}>
                                        <div className={style.graphRightContainer}>
                                            <div className={style.graphRightHalf}></div>
                                        </div>
                                    </div>
                                    <i className="fas fa-book-open "></i>
                                    <div className={style.factsWrapNum}>
                                        <span className={style.counter}>{props.props.releasecount}</span>
                                    </div>
                                </div>
                                <h6>выпуски журналов</h6>
                            </div>
                        </div>
                        <div className="">
                            <div className={style.factsWrap}>
                                <div className={style.graph}>
                                    <div className={style.graphLeftSide}>
                                        <div className={style.graphLeftContainer}>
                                            <div className={style.graphLeftHalf}></div>
                                        </div>
                                    </div>
                                    <div className={style.graphRightSide}>
                                        <div className={style.graphRightContainer}>
                                            <div className={style.graphRightHalf}></div>
                                        </div>
                                    </div>
                                    <i className="far fa-file-alt"></i>
                                    <div className={style.factsWrapNum}>
                                        <span className={style.counter}>{props.props.articlecount}</span>
                                    </div>
                                </div>
                                <h6>PDF-статьи</h6>
                            </div>
                        </div>


                    </div>
                </Row>
            </Container>
        </div>
    )
}
export default Statistics;
