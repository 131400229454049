import React, {useEffect, useState} from 'react';
import {NavLink, Redirect, withRouter} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import * as axios from "axios";
import {baseURL} from "../../../api/api";
import Preloader from "../../common/Preloader/Preloader";

function  EditReleases  (props)  {

    const [journalName, setJournalName] = useState([]);


    const [name, setName] = useState("");
    const [file, setFile] = useState("");
    const [document, setDocument] = useState("");
    const [tome, setTome] = useState("");
    const [issn, setIssn] = useState("");
    const [date, setDate] = useState("");
    const [number, setNumber] = useState("");
    const [journals_id, setJournals_id] = useState("");
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        getJournalName();
        getReleasesData(props.match.params.id);

    }, []);

    if (!localStorage.getItem('user-info')) return <Redirect to='login'/>
    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user-info');


    function getJournalName() {
        axios.get(baseURL+'api/journals')
            .then(response => {
                setJournalName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }
    function getReleasesData(id) {
        axios.get(baseURL+'api/releaseoneid/'+id)
            .then(response => {
                setName(response.data.item.name);
                setTome(response.data.item.tome);
                setNumber(response.data.item.number);
                setIssn(response.data.item.issn);
                console.log(response.data)
            })
            .catch(error => console.error(error));
    }

    function onChangeJournal(e) {
        setJournals_id(e.target.value);
    }

    function addReleases(){
        setLoader(true);
        setError('')
        console.log(name, tome, issn, date, user, journals_id, file, number);
        const formData = new FormData();
        // formData.append('name', name);
        formData.append('id', props.match.params.id);
        formData.append('tome', tome);
        formData.append('issn', issn);
        formData.append('date', date);
        formData.append('create_user_name', user);
        formData.append('journals_id', journals_id);
        formData.append('file', file);
        formData.append('document', document);
        formData.append('number', number);


        axios({
            method: 'post',
            url: baseURL+'api/releaseupdate'+"?_method=PUT",
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
            alert("Изменения сохранены")
            setLoader(false);

            window.location.reload();
        })
            .catch(function (error) {
                setLoader(false);
                setError('Ошибка при загрузке')
            });



    }
    return (
        <>
            <div className='container'>
                <h1 className='mb-5  mt-4'>Редактирование журнала</h1>
                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6"><NavLink to={"/admin/alljournals"}><Button variant="dark">Список журналов / Создать новый журнал</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/allreleases"}><Button variant="dark">Список выпусков журналов</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addreleases"}><Button disabled variant="dark">Добавить новый выпуск </Button></NavLink></div>
                </div>

            </div>

            <div className="col-lg-12">
                <div className="container">
                    <br/>
                    <h2> <br/> <span className='red'>{name}</span></h2>
                    <h6 className='text-secondary'>Пустые поля останутся без изменений</h6>
                    <hr/>
                    <label>Выберите журнал:</label><br/>
                    <select onChange={onChangeJournal} className="form-control">
                        <option value={0}/>
                        {
                            journalName.map((item) =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                        }
                    </select> <br/>
                    {/*<label>Название</label>*/}
                    {/*<input type="text" className="form-control"*/}
                    {/*onChange={(e) => setName(e.target.value)}*/}
                    {/*placeholder="Название"/> <br/>*/}
                    <label>Номер</label>
                    <input type="text" className="form-control"
                           onChange={(e) => setNumber(e.target.value)}
                           value={number}
                           placeholder="Номер"/> <br/>
                    <label>Том</label>
                    <input type="text" className="form-control"
                           value={tome}
                           onChange={(e) => setTome(e.target.value)}
                           placeholder="Том"/> <br/>
                    <label>Issn</label>
                    <input type="text" className="form-control"
                           value={issn}
                           onChange={(e) => setIssn(e.target.value)}
                           placeholder="Issn"/> <br/>
                    <label>Дата выпуска</label>
                    <input type="date" className="form-control"
                           onChange={(e) => setDate(e.target.value)}
                           placeholder="date"/> <br/>

                    <label>Изображение</label>
                    <input type="file" className="form-control"
                           onChange={(e) => setFile(e.target.files[0])}
                           placeholder="file"/> <br/>

                    <label>Сборник</label>
                    <input type="file" className="form-control"
                           onChange={(e) => setDocument(e.target.files[0])}
                           placeholder="file"/> <br/>

                    <button onClick={addReleases} className="btn btn-primary">Сохранить изменения</button>
                    { loader ? <Preloader/> : '' }
                    <p className='red'>{error}</p>
                </div>
            </div>
        </>
    )


}

export default withRouter(EditReleases);