import {NavLink, withRouter} from "react-router-dom";
import {Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {baseURL} from "../../api/api";
import * as axios from "axios";
import getCookie from "../../utils/customFunctions";


const EducationInfo = (props) => {

    const [data, setdata] = useState({});
    const [myBooks, setMyBooks] = useState([]);


    useEffect(() => {
        getsetDepartmentName();
        getMyAddedBooks()
    }, []);

    function getsetDepartmentName() {

        axios.get(baseURL + 'api/oneeducational/' + props.match.params.id)
            .then(response => {
                setdata(response.data.items);
            })
            .catch(error => console.error(error))
    }

    function getMyAddedBooks() {
        const config = {
            headers: {Authorization: `Bearer ${getCookie('token')}`}
        };

        axios.get('https://backend-isu.gstou.ru/api/elib/favorite/',
            config
        )
            .then(response => {
                setMyBooks(response.data)
            })
            .catch(error => console.error(error))
    }

    function addBook() {
        var bodyFormData = new FormData();
        bodyFormData.append('book', data.id);

        axios({
            method: 'post',
            url: 'https://backend-isu.gstou.ru/api/elib/favorite/',
            headers: {Authorization: `Bearer ${getCookie('token')}`},
            data: bodyFormData
        })
            .then(resp => { getMyAddedBooks()})
            .catch(error => console.error(error))


    }

    let file = data.file ? data.file.slice(12) : null;
    let bookIncludes = myBooks.find(book => book.id === data.id)
    return (
        <>

            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>


                            <NavLink to={'/учебныеиздания'}>
                                <div className={'breadcrumbItem'}>Учебно-методические пособия</div>
                            </NavLink>


                            <div className={'breadcrumbItem'}>{data.name}</div>


                        </div>
                    </Row>
                </Container>
            </div>
            <div className='container mt-4'>

                <div className='row'>
                    <div className="col-12">
                        <h2>{data.name}</h2>
                        <hr/>
                        <p className='text-secondary'>Учебное издание </p>

                        <p><i className="fas fa-user-alt"></i>
                            {Array.isArray(data.author) ?
                                data.author.map((item, index) => {
                                    if (index === (data.categories_id.length - 1)) {
                                        return <span key={item.id}><NavLink
                                            to={'/учебныеиздания/автор/' + item.id}><span> {item.name}</span></NavLink></span>
                                    }
                                    return <span key={item.id}><NavLink
                                        to={'/учебныеиздания/автор/' + item.id}><span> {item.name}</span></NavLink>, </span>
                                })
                                : null

                            }

                        </p>
                        <p>
                            <i className="fas fa-file-alt"></i> {data.publishers}
                        </p>
                        <p>
                            <b>Страниц:</b> {data.pages}
                        </p>
                        <p>
                            <b>Направление:</b>
                            {Array.isArray(data.categories_id) ?
                                data.categories_id.map((item, index) => {
                                    if (index === (data.categories_id.length - 1)) {
                                        return <span key={item.id}><span> {item.name}</span></span>
                                    }
                                    return <span key={item.id}><span> {item.name}</span>, </span>
                                })
                                :
                                null

                            }
                        </p>
                        {data.hide == 0 ?
                            <NavLink to={'/документ/' + file + '/' + data.name} >
                                <button className='btn-primary btn w-25'>Посмотреть <i className="far fa-file-pdf"></i>
                                </button>
                            </NavLink>
                            : <a href={baseURL + data.file} target='_blank'>
                                <button className='btn-primary btn w-25'>Открыть <i className="far fa-file-pdf"></i>
                                </button>
                            </a>

                        }
                        {
                            !bookIncludes ?
                                <button onClick={addBook} className='btn-outline-success btn w-25 ml-2'>В избранное <i
                                    className="far fa-star"></i>
                                </button>
                                :
                                <button onClick={addBook} className='btn-success btn w-25 ml-2'>В библиотеке <i
                                    className="fa fa-check"></i>
                                </button>
                        }


                        <hr/>
                        <h4><i className="far fa-comment-dots"></i> Аннотация</h4>
                        <p>{data.annotations}</p>
                        <h4><i className="fas fa-info-circle"></i> Описание</h4>
                        <p>
                            {data.description}
                        </p>
                        {data.hide == 0 ?
                            null
                            : <div><h4 className='mt-3'>Прямая ссылка на файл статьи:</h4>
                                <div>
                                    <input onChange={() => {
                                    }} className='form-control' onClick={(e) => e.target.select()} type="text"
                                           value={baseURL + data.file}/>

                                </div>
                            </div>

                        }

                    </div>
                </div>

            </div>
        </>
    )
}
export default withRouter(EducationInfo);