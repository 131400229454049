import React, {useEffect, useState} from 'react';
import * as axios from "axios";
import {NavLink, withRouter} from "react-router-dom";
import photo from "../../assets/images/book.png";
import {baseURL} from "../../api/api";
import style from "./Education.module.css";
import {Container, Row} from "react-bootstrap";

const Author = (props) => {

    const [educational, setEducational] = useState([[]]);
    const [count, setCount] = useState('');
    const [author, setAuthor] = useState({});


    useEffect(() => {
        getEducational();
    }, []);

    function getEducational() {
         axios.get(baseURL+'api/educationaloneauthor/' + props.match.params.id)
            .then(response => {
                setEducational(response.data.items);
                setCount(response.data.count);
                setAuthor(response.data.author);
            })
            .catch(error => console.error(error));
    }



    return (
        <div >
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbItem'}>Учебно-методические пособия</div>
                            </NavLink>
                            <NavLink to={'/учебныеиздания/авторы'}>
                                <div className={'breadcrumbItem'}>По авторам</div>
                            </NavLink>
                            <div className={'breadcrumbItem'}>{author?.name}</div>

                        </div>
                    </Row>
                </Container>
            </div>

            <div className="container mt-4  ">

                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h1>Статьи</h1>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li ><NavLink to={'/учебныеиздания'}><p>По дате публикации</p></NavLink></li>
                                    <li className={style.current}><NavLink to={"/учебныеиздания/авторы"}><p>По авторам</p></NavLink></li>
                                    <li ><NavLink to={"/учебныеиздания/институты"}><p>По институтам</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <div><h1>Автор <span className="red">{author?.name}</span></h1></div>
                        <div><h4>Количество учебно-методических пособий: <span className="red">{count}</span></h4></div>
                        {educational.length?
                            educational.map( (item) => (
                             <div key={item.id}>
                                <div className="row">
                                    <div className="col-2">
                                        <img src={photo} alt=""/>
                                    </div>
                                    <div className="col-10">
                                        <div className='row mb-3'>
                                            <div className="col-12 text-secondary">Учебное издание</div>
                                            <div className='col-12'><NavLink to={'/учебныеиздания/учебник/'+item.id}>{item.name}</NavLink></div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-12">
                                                Дата публикации: {item.date && item.date.slice(0,4)}
                                                <br/>
                                                Направление: {item.categories_id}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            )):null
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default withRouter(Author);