import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import articlesReducer from "./articles-reducer";

import { reducer as formReducer } from "redux-form";
import articleReducer from "./article-reducer";
import journalsReducer from "./journals-reducer";
import releasesReducer from "./releases-reducer";
import educationalReducer from "./educational-reducer";
import { IS_DEV } from "../shared/constants";
import { profEducationReducer } from "./prof-education";
import { iprSmartReducer } from "./ipr-smart";
import disertationReducer from "./disertation-reducer";

let reducers = combineReducers({
    articlesPage: articlesReducer,
    articlePage: articleReducer,
    journalsPage: journalsReducer,
    releasesPage: releasesReducer,
    educationalDatePage: educationalReducer,
    form: formReducer,
    profEducation: profEducationReducer,
    iprSmart: iprSmartReducer,
    disertation: disertationReducer,
});

const composeEnhancers =
    IS_DEV && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));

window.__store__ = store;

export default store;
