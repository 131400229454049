import style from './Disertation.module.css';
import {NavLink} from "react-router-dom";
import React from "react";
import Paginator from "../common/Paginator/Paginator";
import DisertationsItem from './DisertationsItem';
import { Table } from 'react-bootstrap';



const Disertations = (props) => {
    return (
        <div className={style.contInst}>
            <div className='container mt-4'>
                <div className='row'>
                    <div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h2>Диссертации</h2>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li ><NavLink to={"/диссертации/авторы"}><p>По авторам</p></NavLink></li>
                                    <li className={style.current}><NavLink to={"/диссертации/направления"}><p>По направлениям</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <h2>Диссертации - <span className='red'>{props?.napravlenie}</span></h2>
                        <hr/>
                        <h4 className='mb-1'>Направление: <span className='red'>{props?.spec}</span></h4>
                        <h4 className='mb-4'>Количество: <span className='red'>{props?.totalCount}</span></h4>
                        <Paginator currentPage={props.currentPage} onPageChanged={props.onPageChanged}
                                   totalItemsCount={props.totalCount} pageSize={props.pageSize}/>
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>Название</th>
                                    <th>Год</th>
                                    <th>Авторы</th>
                                </tr>
                            </thead>
                            <tbody>
                            {props.articles?.length  ?
                                props.articles.map(u =>(
                                    <DisertationsItem
                                        article={u}
                                        key={u.id}/>

                                )):null
                            }
                            </tbody>
                            
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Disertations;