    import React from 'react';
import style from './Article.module.css';
import {Container, Row} from "react-bootstrap";
import ArticleSidebar from "./ArticleSidebar";
import ArticleContent from "./ArticleContent";
import {NavLink} from "react-router-dom";


const Article = (props) => {
    return (
        <div>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>

                            <NavLink to={'/статьи'}>
                                <div className={'breadcrumbItem'}>cтатьи</div>
                            </NavLink>
                            {props?.article?.item?.name
                                ? <div className={'breadcrumbItem'}>{props.article.item.name.slice(0, 75)+'...'}</div>
                                : null
                            }

                        </div>
                    </Row>
                </Container>
            </div>
            <Container className={style.cont}>
                <Row>
                    <div className="col-12 col-md-4 order-md-1 order-2">
                        <ArticleSidebar release={props.article?.otherArticles} id={props.id}/>
                    </div>
                    <div className="col-12 col-md-8 order-md-2 order-1">
                        <ArticleContent article={props.article?.item}/>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default Article;