import style from "./Disertation.module.css";
import { NavLink } from "react-router-dom";
import { baseURL } from "../../api/api";

const DisertationsItem = (props) => {

    const onClick = props.article.onClick;
    const NavComponent = onClick ? "span" : NavLink;
    const navProps = onClick
        ? {
              onClick,
              style: {
                  cursor: "pointer",
              },
          }
        : { to: "/диссертация/" + props?.article?.id };

    return (
        <tr>
            <td>
                <NavComponent {...navProps}>
                <div className={style.Edutitle}>
                    {props.article?.name ? props?.article?.name : null}
                </div>
                </NavComponent>
            </td>
            <td>{props?.article?.year === 'null' ? '-' : props?.article?.year}</td>
            <td>{typeof props.article.author === "string"
                ? props.article?.author
                : props.article?.author?.length
                ? props.article?.author?.map((item) => (
                        <span key={item?.id}>{item?.name + ",\n"}</span>
                    ))
                : '-'}
            </td>
       </tr>
           
    );
};
export default DisertationsItem;
