
import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import * as axios from "axios";

import {baseURL} from "../../../api/api";
import checkToken from "../../common/Protected/CheckToken";


export const AllDisertation = () => {
    const [data, setData] = useState([[], []]);

    useEffect(() => {
        getData();
        checkToken();
    }, []);

    let token = localStorage.getItem('token');

    async function deleteOperation(id) {
        await fetch(baseURL+"api/disertationdelete/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + token
            }
        });
        getData();
    }

    function getData() {
        axios.get(baseURL+'api/disertationforadmin')
            .then(response => {
                setData(response.data.items);
                 console.log(response.data.items)
            })
            .catch(error => console.error(error));
    }

    return (
        <>
            <div className='container'>
                <h1 className='mb-5   mt-4'>Список диссертаций</h1>

                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-3">
                <div >
                        <NavLink to={"/admin/alldisertation"}><Button variant="dark">Список диссертаций</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">

                   
                    <div className="col-3">
                        <NavLink to={"/admin/adddisertation"}><Button variant="secondary">Добавить диссертацию</Button></NavLink>
                    </div>
                    <div className="col-3"><NavLink to={"/admin/addauthordisertation"}><Button variant="secondary">Авторы</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addnapravleniedisertation"}><Button variant="secondary">Направления</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addspecdisertation"}><Button variant="secondary">Специальности</Button></NavLink></div>
                </div>

            </div>
            <div className="col-lg-12">
                <Table>

                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>NAME</td>
                            <td>FILE</td>
                            <td>UPDATE</td>
                            <td><span className="red">DELETE</span></td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item) =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td><a download href={baseURL+item.file_path} target="_blank">{baseURL+item.file_path}</a> </td>

                                <td>
                                    <NavLink to={"/admin/editdisertation/" + item.id}>
                                        <span className="text-secondary">Изменить </span>
                                    </NavLink>
                                    <br/>


                                </td>
                                <td>
                                    <button onClick={() => deleteOperation(item.id)}
                                            className="btn btn-outline-danger">Удалить
                                    </button>
                                </td>

                            </tr>
                        )

                    }
                    </tbody>
                </Table>
            </div>
        </>
    )


}