import React, {useEffect, useState} from 'react';
import style from './Disertation.module.css';
import {Container, Row} from "react-bootstrap";
import {NavLink, withRouter} from "react-router-dom";
import * as axios from "axios";
import {baseURL} from "../../api/api";

const Spec = (props) => {
    const [institute, setInstitute] = useState([]);
    const [instituteList, setInstituteList] = useState([]);
    const [name, setName] = useState('')
    const [napravlenie, setNapravlenie] = useState('')
    useEffect(() => {
        getInstituteName();
    }, []);
    useEffect(() => {
        if(name){
            setInstituteList(institute.filter(item=> item.name.toUpperCase().includes(name.toUpperCase())))
        }else{
            setInstituteList(institute);
        }
    }, [name,institute]);

    function getInstituteName() {
        axios.get(baseURL+'api/disertationspec/'+props.match.params.id)
            .then(response => {
                setInstitute(response.data?.items);
                setNapravlenie(response.data?.napravlenie?.name);
            })
            .catch(error => console.error(error))
    }
    let i =0;
    return (
        <div className={style.contInst}>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>
                            
                                <div className={'breadcrumbItem'}>Диссертации</div>
                           

                                <div className={'breadcrumbItem'}>Направления</div>
                                <div className={'breadcrumbItem'}>{napravlenie}</div>


                        </div>
                    </Row>
                </Container>
            </div>
            <div  className={style.Authcont + ' container mt-4 ' + style.contInst}>
                <div className="row"><div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h2>Диссертации</h2>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li ><NavLink to={"/диссертации/авторы"}><p>По авторам</p></NavLink></li>
                                    <li className={style.current}><NavLink to={"/диссертации/направления"}><p>По направлениям</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                    <span style={{fontSize: 16, color: 'red'}}>{napravlenie}</span>
                    <h1>Специальности </h1>
                        <input
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                    placeholder='Поиск'
                                    type="text"
                                    className='form-control mb-3 '/>
                        {instituteList.length ?
                            instituteList.map(item =>(
                                <div key={item.id} className={style.DepItem + "  mt-1"}>
                                    <div className={style.card + " card  pl-2"}>
                                        <NavLink className='p-1' to={'/диссертации/'+item.id}>
                                            <div className='row align-items-center h-100'>
                                                <div className='col-10 '><div className=''>{item.name}</div></div>
                                            </div>
                                        </NavLink>

                                    </div>

                                </div>


                            )):null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withRouter(Spec) ;