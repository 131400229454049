import React, {useEffect, useState} from 'react';
import Search from "./Search/Search";
import Section from "./Sections/Section";
import Statistics from "./Statistics/Statistics";
import Keywords from "./Keywords/Keywords";
import * as axios from "axios";
import {baseURL} from "../../api/api";
import { Integration } from './Integration';

const Main =(props) =>{

    const [data, setData] = useState({'keywords':[]});

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        axios.get(baseURL+'api/main')
            .then(response => {
                setData(response.data);
            })
            .catch(error => console.error(error))
    }
    return (
        <div >
            <Search />
            <Section props={data}/>
            <Statistics props={data}/>
            <hr className='d-block d-sm-none'/>
            <Keywords props={data.keywords}/>
            <Integration />
        </div>
    )
}

export default Main;