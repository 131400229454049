import {articlesAPI} from "../api/api";

const SET_JOURNALS = 'SET_JOURNALS';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';



let initialState = {
    journals: [],
    isFetching: true
};

const journalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_JOURNALS: {
            return {...state, journals: action.journals}
        }
        case TOGGLE_IS_FETCHING: {
            return {...state, isFetching: action.isFetching}
        }
        default:
            return state;
    }
}



export const setJournals = (journals) => ({type: SET_JOURNALS, journals});
export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, isFetching});

export const requestJournals = () =>{
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        let data = await articlesAPI.getJournals();
        dispatch(toggleIsFetching(false));
        dispatch(setJournals(data));
    }
}

export default journalsReducer
