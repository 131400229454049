import React, {useEffect, useState} from 'react';
import {NavLink, Redirect} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import * as axios from "axios";
import {baseURL} from "../../../api/api";
import OneJournal from "./OneJournal";
import checkToken from "../../common/Protected/CheckToken";

export const AllJournals = () => {


    const [data, setData] = useState([[], []]);
    const [name, setName] = useState("");

    useEffect(() => {
        getData();
        checkToken();
    }, []);


    if (!localStorage.getItem('user-info')) return <Redirect to='login'/>
    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user-info');

    async function deleteOperation(id) {
        let result = await fetch(baseURL+"api/journaldelete/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + token
            }

        });

        result = await result.json();
        getData();

    }
    async function updateOperation(id, name) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        axios({
            method: 'post',
            url: baseURL+'api/journalupdate'+"?_method=PUT",
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            getData();
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }

    function getData() {
        axios.get(baseURL+'api/journals')
            .then(response => {
                setData(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }

    async function addJournal() {
        console.log(name);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('create_user_name', user);

        axios({
            method: 'post',
            url: baseURL+'api/journaldadd',
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
            alert("Новый журнал добавлен");

            window.location.reload();
        })
            .catch(function (error) {
                console.log(error);
            });


    }

    return (
        <>
            <div className='container'>
                <h1 className='mb-5  mt-4'>Список журналов</h1>
                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <NavLink to={"/admin/alljournals"}><Button disabled variant="dark">Список журналов / Создать новый журнал</Button></NavLink>
                    </div>

                    <div className="col-3"><NavLink to={"/admin/allreleases"}><Button variant="dark">Список выпусков журналов</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addreleases"}><Button variant="dark">Добавить новый выпуск </Button></NavLink></div>

                </div>

            </div>
            <div className="col-lg-12">
                <div className="container">
                    <label>Добавить новый журнал:</label><br/>
                    <input type="text" className="form-control"
                           onChange={(e) => setName(e.target.value)}
                           placeholder="Название журнала"/>
                    <button onClick={addJournal} className="btn btn-primary">Добавить</button>
                    <br/>
                </div>

                <h2 className='mb-5  mt-4 text-center'>Список журналов</h2>


                <Table>

                    <thead>
                    <tr>
                        <td>ID</td>
                        <td>ИМЯ</td>
                        <td>ИЗМЕНИТЬ</td>
                        <td><span className="red">УДАЛИТЬ</span></td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item) =>
                            <OneJournal key={item.id} deleteOperation={deleteOperation} updateOperation={updateOperation} item={item} />

                        )

                    }
                    </tbody>
                </Table>
            </div>
        </>
    )


}