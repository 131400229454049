import {useEffect, useState} from 'react'
import React from "react";
import * as axios from "axios";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import style from "./../Article/form.module.css";
import {Button} from "react-bootstrap";
import Select from "react-select";
import {baseURL} from "../../../api/api";
import Preloader from "../../common/Preloader/Preloader";


function EditEducational(props) {
    const [name, setName] = useState("");
    const [file, setFile] = useState("");
    const [image, setImage] = useState("");
    const [date, setDate] = useState("");
    const [annotations, setAnnotations] = useState("");
    const [description, setDescription] = useState("");
    const [publishers, setPublisher] = useState("");
    const [pages, setPages] = useState("");
    const [hide, setHide] = useState(false);

    const [categoriesName, setCategoriesName] = useState([[],[]]);
    const [categories, setCategories] = useState("");
    const [categoriesforaxios, setCategoriesforaxios] = useState('');


    const [releasesName, setReleasesName] = useState([[], []]);
    const [releases, setReleases] = useState('');
    const [releasesforaxios, setReleasesforaxios] = useState('');



    const [authorName, setAuthorName] = useState([[], []]);
    const [author, setAuthor] = useState('');
    const [authorforaxios, setAuthorforaxios] = useState('');

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState('');

    useEffect(()=>{
        console.log(name, annotations, description, releases, authorforaxios, file)
    },[name, annotations, description, releases, authorforaxios,  file])


    useEffect(() => {
        getAuthorName();
        getCategoriesName();
        getDepartmnet();
        getEducationalData(props.match.params.id);
    }, []);
    useEffect(() => {
        let stringAuthor = ',';
        for (let key in author) {
            stringAuthor = stringAuthor + author[key].value + ',';
        }
        setAuthorforaxios(stringAuthor);
    }, [author]);
    useEffect(() => {
        let stringReleases = ',';
        for (let key in releases) {
            stringReleases = stringReleases + releases[key].value + ',';
        }
        setReleasesforaxios(stringReleases);
    }, [releases]);
    useEffect(() => {
        let stringCategories = ',';
        for (let key in categories) {
            stringCategories = stringCategories + categories[key].value + ',';
        }
        setCategoriesforaxios(stringCategories);
    }, [categories]);

    function getEducationalData(id) {
        axios.get(baseURL+'api/oneeducational/'+id)
            .then(response => {
                setName(response.data.items.name);
                setPublisher(response.data.items.publishers);
                setDescription(response.data.items.description);
                setAnnotations(response.data.items.annotations);
                setPages(response.data.items.pages);
                response.data.items.hide==0?setHide(false):setHide(true)
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));
    }
    function getCategoriesName() {
        axios.get(baseURL+'api/educationalcategories')
            .then(response => {
                setCategoriesName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));
    }
    function getDepartmnet() {
        axios.get(baseURL+'api/educationalsdepartment')
            .then(response => {
                setReleasesName(response.data);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));
    }

    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user-info');



    function getAuthorName() {
        axios.get(baseURL+'api/educationalauthors')
            .then(response => {
                setAuthorName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error))
    }




    async function addArticle() {
        setLoader(true);
        setError('')
        console.warn(name, annotations, description, releases, authorforaxios, user, file);
        const formData = new FormData();
        formData.append('id', props.match.params.id);
        formData.append('file', file);
        formData.append('image', image);
        formData.append('publishers', publishers);
        formData.append('pages', pages);
        formData.append('annotations', annotations);
        formData.append('categories_id', categoriesforaxios);
        formData.append('depatments_id', releasesforaxios);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('author', authorforaxios);
        formData.append('hide', hide);
        formData.append('create_user_name', user);
        formData.append('date', date);


        axios({
            method: 'post',
            url: baseURL+'api/educationalupdate'+"?_method=PUT",
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
            setLoader(false);
            alert("Сохранено!")
            window.location.reload();
        })
            .catch(function (error) {
                setLoader(false);
                setError('Ошибка при загрузке')
                console.log(error);
            });



    }

    let selectOptionAuthor = [];
    authorName.forEach(item => {

        selectOptionAuthor.push({value: item.id, label: item.name})
    });
    let selectOptionReleases = [];
    releasesName.forEach(item => {

        selectOptionReleases.push({value: item.id, label: item.name})
    });

    let selectOptionCategories = [];
    categoriesName.forEach(item => {

        selectOptionCategories.push({value: item.id, label: item.name})
    });


    return (
        <div>
            <div className={'container ' + style.form}>
                <h1 className="mt-4">Редактировать учебное издание</h1>

                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">


                    <div className="col-3">
                        <NavLink to={"/admin/alleducation"}><Button variant="dark">Список учебных издания</Button></NavLink>
                    </div>
                    <div className="col-3">
                        <NavLink to={"/admin/addeducation"}><Button variant="success">Добавить
                            учебное издание</Button></NavLink>
                    </div>
                    <div className="col-3"><NavLink to={"/admin/addauthoreducation"}><Button variant="secondary">Добавить автора
                        / список</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addcategorieseducation"}><Button variant="secondary">Добавить направление</Button></NavLink></div>
                </div>
                <p className='text-danger font-italic'>Пустые поля останутся неизменны</p>

                <br/>
                <label>Название</label>
                <input type="text" className="form-control"
                       value={name}
                       onChange={(e) => setName(e.target.value)}
                       placeholder="Название"/> <br/>
                <label>Описание</label>
                <textarea onChange={(e) => setDescription(e.target.value)} value={description}></textarea>


                <label>Аннотация</label>
                <textarea onChange={(e) => setAnnotations(e.target.value)} value={annotations}></textarea>

                <label>Издание</label>
                <input type="text" className="form-control"
                       onChange={(e) => setPublisher(e.target.value)}
                       value={publishers}
                       placeholder="Издание"/> <br/>

                <label>Количество страниц</label>
                <input type="text" className="form-control"
                       value={pages}
                       onChange={(e) => setPages(e.target.value)}
                       placeholder="Количество страниц"/> <br/>


                <label>Направление</label><br/>
                <Select
                    onChange={(selectedOption) => {
                        setCategories(selectedOption);
                    }}
                    required="required"
                    value={categories}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={selectOptionCategories}
                    placeholder='выберите направления'
                /> <br/>
                <label>Кафедра</label><br/>
                {/*<select onChange={(e)=> setReleases(e.target.value)}   className="form-control">*/}
                {/*    <option value={0}/>*/}
                {/*    {*/}
                {/*        releasesName.map((item) =>*/}
                {/*            <option key={item.id} value={item.id}>{item.name}</option>*/}
                {/*        )*/}
                {/*    }*/}
                {/*</select> <br/>*/}
                <Select
                    onChange={(selectedOption) => {
                        setReleases(selectedOption);
                    }}
                    required="required"
                    value={releases}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={selectOptionReleases}
                    placeholder='выберите кафедры'
                /> <br/>


                <label>Авторы</label>
                <Select
                    onChange={(selectedOption) => {
                        setAuthor(selectedOption);
                    }}
                    required="required"
                    value={author}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={selectOptionAuthor}
                    placeholder='выберите авторов'
                /><br/>
                <label>Дата публикации</label>
                <input type="date" className='form-control'
                       onChange={(e)=> setDate(e.target.value)}/>

                <label>Документ для загрузки</label>
                <input type="file" className="form-control"
                       onChange={(e) => setFile(e.target.files[0])}

                       placeholder="file"/> <br/>
                <label>Фото превью</label>
                <input type="file" className="form-control"
                       onChange={(e) => setImage(e.target.files[0])}

                       placeholder="file"/> <br/>
                <label htmlFor="hide">Открытый доступ &nbsp;</label>
                <input id='hide'  type="checkbox"
                       checked={hide}
                       onChange={(e) => setHide(e.target.checked)}/> <br/><br/>

                <button onClick={addArticle} className="btn btn-primary">Сохранить изменения</button>
                { loader ? <Preloader/> : '' }
                <p className='red'>{error}</p>
            </div>
        </div>
    )
}

export default withRouter(EditEducational);