import React from 'react';
import axios from 'axios';
import style from './Login.module.css';
import {Redirect} from "react-router-dom";
import {baseURL} from "../../api/api";

const Login = () => {



    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [er, setEr] = React.useState('');

    if (localStorage.getItem('user-info')) {
        return <Redirect to='/admin' />
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(baseURL+'api/login', {
            email: email,
            password: password
        }).then(response => {
            if (response.data.user) {
                localStorage.setItem("user-info",JSON.stringify(response.data.user.name).replace(/"/g,""));
                localStorage.setItem("token",(JSON.stringify(response.data.token)).replace(/"/g,""));
            }
            setEr(response.data.message);
        });
    };

    return (
        <div >
            <div className= {'container ' + style.login}>
                <h1>Авторизация</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                    <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                    <button className="btn btn-secondary" type="submit">Login</button>
                    <div>{er}</div>
                </form>

            </div>
        </div>
    );
}

export default Login;