import React from 'react';
import style from './Journals.module.css';
import {baseURL} from "../../api/api";
import {NavLink} from "react-router-dom";

const Releases = (props) =>{


    return (
        <div className={style.cont+ ' container'}>
            <h2 className='mt-5'>{props?.releases?.journal}</h2>
            <div className="row-eq-height row mt-5 mb-5">
                {
                    props?.releases?.items?.map(u =>(
                        <div className="col-6 mb-3" key={u?.id}>
                            <div className="card ">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4"><img src={baseURL+u?.file_path} alt={baseURL+u?.file_path}/></div>
                                        <div className='col-8'>
                                            <div className="row mb-3">
                                                    Номер: {u?.number}
                                            </div>
                                            <div className="row mb-3">Том: {u?.tome}</div>
                                            <div className="row mb-3">issn: {u?.issn}</div>
                                            <div className="row mb-3"> Дата: {u?.date.slice(0, 10)}</div>
                                            {u?.document?
                                                <div className="row mb-3"><a target='_blank' href={baseURL+u?.document} rel="noreferrer">Посмотреть сборник</a></div>
                                                :
                                                null
                                            }

                                            <div className="row">
                                                {!!u?.articleLink && <NavLink to={'/журнал/статья/'+u?.articleLink}>
                                                    <button className='btn btn-primary w-100'>Посмотреть статьи в выпуске</button>
                                                </NavLink>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Releases