import { disertationAPI} from "../api/api";

const SET_DISERTATION = 'SET_DISERTATION';
const SET_DISERTATIONS = 'SET_DISERTATIONS';
const SET_NAPRAVLENIE = 'SET_NAPRAVLENIE';
const SET_SPEC = 'SET_SPEC';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';

const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_TOTAL_ARTICLES_COUNT = 'SET_TOTAL_ARTICLES_COUNT';


let initialState = {
    disertations: [],
    pageSize: 5,
    totalCount: 0,
    currentPage: 1,
    isFetching: true,
    napravlenie: '',
    spec: ''
};

const disertationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISERTATION: {
            return {...state, disertation: action.disertation}
        }
        case SET_DISERTATIONS: {
            return {...state, disertations: action.disertations}
        }
        case SET_SPEC: {
            return {...state, spec: action.spec}
        }
        case SET_NAPRAVLENIE: {
            return {...state, napravlenie: action.napravlenie}
        }
        case SET_CURRENT_PAGE: {
            return {...state, currentPage: action.currentPage}
        }
        case SET_TOTAL_ARTICLES_COUNT: {
            return {...state, totalCount: action.count}
        }
        case TOGGLE_IS_FETCHING: {
            return {...state, isFetching: action.isFetching}
        }
        default:
            return state;
    }
}



export const setDisertation = (disertation) => ({type: SET_DISERTATION, disertation});
export const setDisertations = (disertations) => ({type: SET_DISERTATIONS, disertations});
export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, isFetching});
export const setCurrentPage = (currentPage) => ({type: SET_CURRENT_PAGE, currentPage})
export const setTotalArticlesCount = (totalCount) => ({type: SET_TOTAL_ARTICLES_COUNT, count: totalCount})
export const setSpec = (spec) => ({type: SET_SPEC, spec});
export const setNapravlenie = (napravlenie) => ({type: SET_NAPRAVLENIE, napravlenie});

export const requestDisertations = (page, pageSize, spec) =>{
    return async (dispatch) => {
        dispatch(setDisertations([]));
        dispatch(toggleIsFetching(true));
        let data = await disertationAPI.getDisertations(page, pageSize, spec);
        dispatch(toggleIsFetching(false));
        dispatch(setSpec(data.spec));
        dispatch(setNapravlenie(data.napravlenie));
        dispatch(setDisertations(data.items));
        dispatch(setTotalArticlesCount(data.totalCount));
    }
}
export const requestDisertation = (id) =>{
    return async (dispatch) => {
        dispatch(setDisertation({}));
        dispatch(toggleIsFetching(true));
        let data = await disertationAPI.getDisertation(id);
        dispatch(toggleIsFetching(false));
        dispatch(setDisertation(data));
    }
}

export default disertationReducer
