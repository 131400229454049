import React, {useEffect, useState} from 'react';
import * as axios from "axios";
import {NavLink, withRouter} from "react-router-dom";
import photo from "../../assets/images/book.png";
import {baseURL} from "../../api/api";
import style from "./Disertation.module.css";
import {Container, Row} from "react-bootstrap";

const DisertationOneAuthor = (props) => {

    const [educational, setEducational] = useState([[]]);
    const [count, setCount] = useState('');
    const [author, setAuthor] = useState({});


    useEffect(() => {
        getEducational();
    }, []);

    function getEducational() {
         axios.get(baseURL+'api/disertationoneauthor/' + props.match.params.id)
            .then(response => {
                setEducational(response.data.items);
                setCount(response.data.count);
                setAuthor(response.data.author);
            })
            .catch(error => console.error(error));
    }



    return (
        <div >
            <div className={'breadcrumdBottom'}>
            <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>


                            <NavLink to={'/учебныеиздания'}>
                                <div className={'breadcrumbItem'}>Диссертации</div>
                            </NavLink>


                            <div className={'breadcrumbItem'}>По авторам</div>


                        </div>
                    </Row>
                </Container>
            </div>

            <div className="container mt-4  ">

                <div className="row">
                <div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h2>Диссертации</h2>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li className={style.current}><NavLink to={"/диссертации/авторы"}><p>По авторам</p></NavLink></li>
                                    <li><NavLink to={"/диссертации/направления"}><p>По направлениям</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-8'>
                        <div><h1>Автор <span className="red">{author?.name}</span></h1></div>
                        <div><h4>Количество диссертации: <span className="red">{count}</span></h4></div>
                        {educational.length?
                            educational.map( (item) => (
                             <div key={item.id}>
                                <div className="row">
                                    <div className="col-2">
                                        <img src={photo} alt=""/>
                                    </div>
                                    <div className="col-10">
                                        <div className='row mb-2'>
                                            <div className='col-12'><NavLink to={'/диссертация/'+item.id}>{item.name}</NavLink></div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-12">
                                                Год: {item?.year}
                                                <br/>
                                                Направление: {item?.napravlenie}
                                                <br/>
                                                Специальность: {item?.spec}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            )):null
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default withRouter(DisertationOneAuthor);