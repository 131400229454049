import React, {useEffect, useState} from 'react';
import {NavLink, Redirect} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import * as axios from "axios";
import {baseURL} from "../../../api/api";
import AuthorEducational from './AuthorEducational';

export const AddSpecDisertation = () => {


    const [data, setData] = useState([]);
    
    const [dataKeywordsList, setDataKeywordsList] = useState([]);
    const [napravleniename, setnapravlenieName] = useState("");
    const [journalName, setJournalName] = useState([]);
    const [name, setName] = useState("");
    console.log(napravleniename)
    useEffect(() => {
        getJournalName();
    }, []);
    useEffect(() => {
        if(name){
            setDataKeywordsList(data.filter((a)=> a.name.toLowerCase().includes(name.toLowerCase().trim())))
        }else{
            setDataKeywordsList(data)
        }
    }, [data,name]);

    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user-info');

    async function deleteOperation(id) {
        await fetch(baseURL+"api/deletespecdisertation/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + token
            }

        });

        window.location.reload();
    }
    async function updateOperation(id, name) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        axios({
            method: 'post',
            url: baseURL+'api/updatespecdisertation'+"?_method=PUT",
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            onChangeJournal({target: { value: napravleniename}});
        })
            .catch(function (error) {
                console.log(error);
            });

    }
    function getJournalName() {
        axios.get(baseURL+'api/disertationnapravlenie')
            .then(response => {
                setJournalName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }
    function onChangeJournal(e){
        console.log(e)
        setnapravlenieName(e.target.value)
        axios.get(baseURL+'api/disertationspec/'+e.target.value)
            .then(response => {
                setData(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error))
    }
    async function addAuthor() {
        if(data.filter((a)=> a?.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase())?.length == 0 ){
        const formData = new FormData();
        formData.append('name', name);
        formData.append('napravlenie', napravleniename);

        axios({
            method: 'post',
            url: baseURL+'api/addspecdisertation',
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {

            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
            alert("Специальность добавлена");

            window.location.reload();
        })
            .catch(function (error) {
                console.log(error);
            });

        }else{
            alert('Такая специальность уже есть')
        }
    }

    return (
        <>
            <div className='container'>
                <h1 className='mb-5  mt-4'>Список специальностей</h1>
                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-3">
                <div >
                        <NavLink to={"/admin/alldisertation"}><Button variant="secondary">Список диссертаций</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">

                   
                    <div className="col-3">
                        <NavLink to={"/admin/adddisertation"}><Button variant="secondary">Добавить диссертацию</Button></NavLink>
                    </div>
                    <div className="col-3"><NavLink to={"/admin/addauthordisertation"}><Button variant="secondary">Авторы</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addnapravleniedisertation"}><Button variant="secondary">Направления</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addspecdisertation"}><Button variant="dark">Специальности</Button></NavLink></div>
                </div>

            </div>
            <div className="col-lg-12">
                <div className="container">
                    <label>Выберите направление:</label><br/>
                    <select onChange={onChangeJournal}  className="form-control">
                        <option value={0}/>
                        {
                            journalName.map((item) =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                        }
                    </select> <br/>
                </div>


                <h2 className='mb-5  mt-4 text-center'>Список специальностей</h2>
                {(!!napravleniename && napravleniename > 0) && <div className='container'><label style={{fontWeight: 'bold'}}>Введите специальность</label><br/>
                <input type="text" className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Введите специальность"/> <br />
                <button onClick={addAuthor} disabled={name?.length === 0} className="btn btn-primary">Добавить специальность</button>
                <hr/> </div>}
                

                <Table>

                    <thead>
                    <tr>
                        <td>id</td>
                        <td>Название</td>
                        <td>Изменить</td>
                        <td><span className="red">УДАЛИТЬ</span></td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        dataKeywordsList.map((item) =>
                        <AuthorEducational key={item.id} item={item} updateOperation={updateOperation} deleteOperation={deleteOperation}/>
                    )

                    }
                    </tbody>
                </Table>
            </div>
        </>
    )


}