import React, {useEffect, useState} from 'react';
import style from './Education.module.css';
import {Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import * as axios from "axios";
import {baseURL} from "../../api/api";
import ieLogo from '../../assets/images/ie.jpg';
import itLogo from '../../assets/images/it.jpg';
import itdLogo from '../../assets/images/itd.jpg';
import icadLogo from '../../assets/images/icad.jpg';
import ioLogo from '../../assets/images/io.jpg';
import fspoLogo from '../../assets/images/fspo.jpg';



const Institute = () => {
    const [institute, setInstitute] = useState([]);

    useEffect(() => {
        getInstituteName();
    }, []);

    function getInstituteName() {
        axios.get(baseURL+'api/institute')
            .then(response => {
                setInstitute(response.data.items);

            })
            .catch(error => console.error(error))
    }
    let logo=[itLogo,ioLogo,itdLogo,icadLogo,ieLogo,fspoLogo];
    let i =0;
    return (
        <div className={style.contInst}>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbItem'}>Учебно-методические пособия</div>
                            </NavLink>

                                <div className={'breadcrumbItem'}>По институтам</div>


                        </div>
                    </Row>
                </Container>
            </div>
            <div className="container">
                {institute.length ?
                    institute.map(item =>(
                        <div key={item.id} className={style.DepItem + " row mt-3"}>

                            <div className={style.card + " card col-12 "}>
                                <NavLink className='p-4' to={'/учебныеиздания/кафедры/'+item.id}>
                                    <div className='row align-items-center h-100'>
                                        <div className='col-2'><img src={logo[i++]} alt="logo-institute"
                                                className={style.logoInstitute}/></div>
                                        <div className='col-10 '><div className=''>{item.name}</div></div>
                                    </div>
                                </NavLink>

                            </div>

                        </div>


                    )):null
                }


            </div>
        </div>
    )
}
export default Institute;