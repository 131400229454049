import React from 'react';
import style from './Header.module.css';
import {NavLink} from "react-router-dom";
import Container from "react-bootstrap/Container";
import SvgLogo from '../../assets/images/logo.svg';
import getCookie from "../../utils/customFunctions";
import jwtDecode from "jwt-decode";


const Header = () => {
    return (
        <header className={style.header}>
            <div className={style.headerTop}>
                <Container>
                    <div className="row align-items-center">
                        <div className="col-lg-2 text-center text-lg-left">
                            <NavLink to={'/'}>
                                <img className={style.logo} src={SvgLogo} alt="logo"/>

                            </NavLink>
                        </div>
                        <div className="col-lg-8 text-center text-lg-left">

                            <div className={style.siteName}>ЭБС ГГНТУ «Digital Book»</div>

                        </div>
                        <div className="col-lg-2" style={{color:'#fff'}}>
                            {getCookie('token') ? <><i className="fa fa-user" aria-hidden="true"></i> {jwtDecode(getCookie('token')).username}</> : null}
                        </div>
                    </div>
                </Container>
            </div>

        </header>
    )
};

export default Header;