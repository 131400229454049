import React from 'react';
import {connect} from 'react-redux';
import Preloader from "../common/Preloader/Preloader";
import {compose} from "redux";
import {NavLink, withRouter} from "react-router-dom";
import style from './Journals.module.css';
import {requestJournals} from "../../redux/journals-reducer";
import Journals from "./Journals";
import {Container, Row} from "react-bootstrap";


class JournalsContainer extends React.Component {


    componentDidMount() {
        this.props.requestJournals();
    }


    render() {
        return <>
            <div className={'breadcrumdBottom'}>

                <Container className='p-0'>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>

                            <div className={'breadcrumbItem'}>Журналы</div>

                        </div>
                    </Row>
                </Container>
            </div>
            <div>{this.props.isFetching ? <Preloader/> : null}</div>
            <Journals
                journals={this.props.journals}
            />

        </>
    }
}

let mapStateToProps = (state) => {
    return {
        journals: state.journalsPage.journals,
        isFetching: state.journalsPage.isFetching
    }
}


// export default connect(mapStateToProps, {requestArticle})(ArticleContainer)
export default compose(connect(mapStateToProps, {requestJournals}), withRouter)(JournalsContainer)