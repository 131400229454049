
/**
 * @param {String} actionPrefix значение будет добавляться в начало каждого экшена
 *
 * @returns {Function}  Возвращает функцию для создания экшен крейтеров.
 * Избавляет от необходимости описывать каждый экшен крейтер как функцию.
 */

export const generateCreateAction = (actionPrefix) => {
    return (actionType) => {
        const type = `${actionPrefix}/${actionType}`;
        const actionCreator = (payload) => {
            return {
                type,
                payload,
            };
        };

        actionCreator.toString = () => type;
        actionCreator.type = type;

        return actionCreator;
    };
};
