import * as axios from "axios";

export const baseURL = 'https://back-lib.gstou.ru/';
// export const baseURL = 'http://backend-library/';

const instance = axios.create({

    baseURL: baseURL+'api/',

});


export const articlesAPI = {
    getArticles(currentPage = 1, pageSize = 10) {
        return instance.get(`articles/${pageSize}/${currentPage}`)
            .then(response => {
                return response.data;
            });
    },
    getArticle(articleId) {
        return instance.get(`article/`+articleId)
            .then(response => {
                return response.data;
            });
    },
    getJournals(){
        return instance.get(`journals`)
            .then(response => {
                return response.data;
            });
    },
    getReleases(id){
        return instance.get(`releasePage/`+id)
            .then(response => {
                return response.data;
            });
    }
}

export const educationalAPI={
    getEducationalDate(currentPage = 1, pageSize = 10) {
        return instance.get(`educational/${pageSize}/${currentPage}`)
            .then(response => {
                return response.data;
            });
    },
    getEducationalDepartment(currentPage = 1, pageSize = 10, id=0) {
        return instance.get(`educationalForDepartment/${pageSize}/${currentPage}/${id}`)
            .then(response => {
                return response.data;
            });
    },
}

export const disertationAPI = {
    getDisertations(currentPage = 1, pageSize = 10, spec) {
        return instance.get(`disertation/${pageSize}/${currentPage}/${spec}`)
            .then(response => {
                return response.data;
            });
    },
    getDisertation(id) {
        return instance.get(`onedisertation/`+id)
            .then(response => {
                return response.data.items;
            });
    },
}