import { useState, useMemo, useEffect } from "react";
import _debounce from "lodash/debounce";

export const useDebounceValue = (value, wait = 300) => {
    const [debuncedValue, _setDebouncedValue] = useState(value);
    const setDebouncedValue = useMemo(() => _debounce(_setDebouncedValue, wait), [wait]);

    useEffect(() => {
        setDebouncedValue(value);
    }, [setDebouncedValue, value]);

    return debuncedValue;
};
