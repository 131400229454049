import React, {Component} from 'react';
import {Container, Row} from "react-bootstrap";
import ArticlesSidebar from "./ArticlesSidebar";
import style from './Articles.module.css';
import ArticlesContentContainer from "./ArticlesContentContainer";
import ArticlesAuthor from "./ArticlesAuthor";
import Author from "./Author/Author";
import {NavLink, withRouter} from "react-router-dom";
import Keyword from "./Keyword/Keyword";
import ArticlesKeyword from "./ArticlesKeyword";

class Articles extends Component {
    render() {


        let component = '';
        let breadcrumb='cтатьи';

        if (this.props.page == 'author') {
            component = <ArticlesAuthor/>;
            breadcrumb = 'по авторам'
        } else if (this.props.page == 'authorid') {
            component = <Author id={this.props.match.params.id}/>
            breadcrumb = 'автор'

        }else if (this.props.page == 'keyword') {
            component = <ArticlesKeyword/>
            breadcrumb = 'по ключевым словам'

        }else if (this.props.page == 'keywordid') {
            component = <Keyword id={this.props.match.params.id}/>
            breadcrumb = 'ключевые слова'

        } else {
            component = <ArticlesContentContainer/>
        }

        return (
            <div >
                <div className={'breadcrumdBottom'}>
                    <Container>
                        <Row>
                            <div className={'breadcrumdCont'}>
                                <NavLink to={'/'}>
                                    <div className={'breadcrumbTitle'}>eLibrary</div>
                                </NavLink>

                                <div className={'breadcrumbItem'}>{breadcrumb}</div>


                            </div>
                        </Row>
                    </Container>
                </div>
                <div className={style.cont+' container'}>
                    <Row>
                        <div className="col-12 col-md-4">
                            <ArticlesSidebar page={this.props.page}/>
                        </div>
                        <div className="col-12 col-md-8">
                            {component}
                        </div>
                    </Row>


                </div>

            </div>
        )
    }
}

export default withRouter(Articles);