import React from 'react';
import {connect} from 'react-redux';
import Education from './Education';
import Preloader from "../common/Preloader/Preloader";
import {requestEducation, setCurrentPage} from "../../redux/educational-reducer";
import {Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import style from "./Education.module.css";

class EducationContainer extends React.Component {

    componentDidMount() {
        const {currentPage, pageSize} = this.props;
        this.props.requestEducation(currentPage, pageSize);
    }


    onPageChanged = (pageNumber) => {
        const {pageSize} = this.props;
        this.props.requestEducation(pageNumber, pageSize);
    }

    render() {

        return <>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>


                            <div className={'breadcrumbItem'}>Учебно-методические пособия</div>


                        </div>
                    </Row>
                </Container>
            </div>


            <div className='container mt-4'>

                <div className='row'>
                    <div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h1>Учебно-методические пособия</h1>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li className={style.current}><NavLink to={'/учебныеиздания'}><p>По дате
                                        публикации</p></NavLink></li>
                                    <li><NavLink to={"/учебныеиздания/авторы"}><p>По авторам</p></NavLink></li>
                                    <li><NavLink to={"/учебныеиздания/институты"}><p>По институтам</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-8">
                        {this.props.isFetching ? <Preloader/> : null}
                        <Education
                            totalCount={this.props.totalCount}
                            pageSize={this.props.pageSize}
                            currentPage={this.props.currentPage}
                            onPageChanged={this.onPageChanged}
                            articles={this.props.articles}
                        />
                    </div>
                </div>

            </div>

        </>
    }
}

let mapStateToProps = (state) => {
    return {
        totalCount: state.educationalDatePage.totalCount,
        pageSize: state.educationalDatePage.pageSize,
        currentPage: state.educationalDatePage.currentPage,
        articles: state.educationalDatePage.articles,
        isFetching: state.educationalDatePage.isFetching
    }
}


export default connect(mapStateToProps, {setCurrentPage, requestEducation})(EducationContainer)