import React, {useEffect, useState} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import * as axios from "axios";
import articlesPhoto from "../../assets/images/book.png";
import {baseURL} from "../../api/api";
import style from './Search.module.css'
import {Container, Row} from "react-bootstrap";
import Preloader from "../common/Preloader/Preloader";

const Search = (props) =>{


    const [articles, setArticles] = useState([{id:1}]);
    const [preload, setPreload] = useState(false);
    let searchType =props.match.params.sect;
    if (searchType===undefined){
        searchType = 'articles';
    }
    useEffect(() => {
        gerSearch();
    }, []);

    function gerSearch() {
        setPreload(false);
        axios.get(baseURL+'api/articlesearch/' + props.match.params.search+'/'+searchType)
            .then(response => {
                setArticles(response.data);
                setPreload(true);
            })
            .catch(error => console.error(error));
    }

    return (

        <div >
            <div className={'breadcrumdBottom'}>
                <Container>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>

                            <div className={'breadcrumbItem'}>Поиск</div>


                        </div>
                    </Row>
                </Container>
            </div>
            <div className={style.cont + ' container'}>
                <h1>Результаты поиска: {props.match.params.search}</h1>
                {!preload?<Preloader/>:null}
                {
                    (props.match.params.sect==='articles')?articles.map(function (item) {
                        return <div key={item.id}>
                            <div className="row">
                                <div className="col-12">
                                    <div className='row mb-3'>
                                        <div className="col-12 text-secondary">Статья</div>
                                        <div className='col-12'><NavLink
                                            to={'/журнал/статья/' + item.id}>{item.name}</NavLink></div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-9">
                                            udk: {item.udk}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col">
                                            Ссылка: <a target='_blank' href={baseURL + item.file_path}>{baseURL + item.file_path}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>

                        </div>
                    }):null
                }

                {
                    (props.match.params.sect==='books')?articles.map(function (item) {
                        return <div key={item.id}>
                            <div className="row">
                                <div className="col-12">
                                    <div className='row mb-3'>
                                        <div className="col-12 text-secondary">Учебное издание</div>
                                        <div className='col-12'><NavLink
                                            to={'/учебныеиздания/учебник/' + item.id}>{item.name}</NavLink></div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-9">
                                            Издание: {item.publishers}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr/>

                        </div>
                    }):null
                }
            </div>
        </div>
    )
}
export default withRouter(Search);