import {useEffect, useState} from 'react'
import React from "react";
import * as axios from "axios";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import style from "./form.module.css";
import {Button} from "react-bootstrap";
import Select from "react-select";
import {baseURL} from "../../../api/api";
import Preloader from "../../common/Preloader/Preloader";


function EditArticle(props) {

    const [name, setName] = useState("");
    const [file, setFile] = useState("");
    const [udk, setUdk] = useState("");
    const [description, setDescription] = useState("");

    const [journalName, setJournalName] = useState([]);
    const [journal, setJournal] = useState("");

    const [releasesName, setReleasesName] = useState([]);
    const [releases, setReleases] = useState([[],[]]);



    const [authorName, setAuthorName] = useState([[], []]);
    const [author, setAuthor] = useState('');
    const [authorforaxios, setAuthorforaxios] = useState('');

    const [keywordName, setKeywordName] = useState([[], []]);
    const [keyword, setKeyword] = useState('');
    const [keywordforaxios, setKeywordforaxios] = useState('');

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState('');



    useEffect(() => {
        getJournalName();
        getAuthorName();
        getKeywordName();
        getArticleData(props.match.params.id);

    }, []);
    useEffect(() => {
        let stringAuthor = ',';
        for (let key in author) {
            stringAuthor = stringAuthor + author[key].value + ',';
        }
        setAuthorforaxios(stringAuthor);
    }, [author]);

    useEffect(() => {
        let stringKeyword = ',';
        for (let key in keyword) {
            stringKeyword = stringKeyword + keyword[key].value + ',';
        }
        setKeywordforaxios(stringKeyword);
    }, [keyword]);


    let token = localStorage.getItem('token');

    function getArticleData(id) {
        axios.get(baseURL+'api/article/'+id)
            .then(response => {
                setName(response.data.item.name);
                setUdk(response.data.item.udk);
                setDescription(response.data.item.description);
                setName(response.data.item.name);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));
    }
    function getJournalName() {
        axios.get(baseURL+'api/journals')
            .then(response => {
                setJournalName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }

    function getAuthorName() {
        axios.get(baseURL+'api/authors')
            .then(response => {
                setAuthorName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error))
    }
    function getKeywordName() {
        axios.get(baseURL+'api/keywords')
            .then(response => {
                setKeywordName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error))
    }
    function onChangeJournal(e){
        setJournal(e.target.value);

        axios.get(baseURL+'api/release/'+e.target.value)
            .then(response => {
                setReleasesName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error))
    }
    function onChangeReleases(e){
        setReleases(e.target.value);
    }

    async function addArticle() {
        setLoader(true);
        setError('')
        const formData = new FormData();
        formData.append('id', props.match.params.id);
        formData.append('file', file);
        formData.append('udk', udk);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('release_id', releases);
        formData.append('author', authorforaxios);
        formData.append('keywords', keywordforaxios);


        axios({
            method: 'post',
            url: baseURL+'api/articleupdate'+"?_method=PUT",
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            alert("Статья изменена");
            window.location.reload();
        })
            .catch(function (error) {
                setLoader(false);
                setError('Ошибка при загрузке')
                console.log(error);
            });



    }

    let selectOptionAuthor = [];
    authorName.forEach(item => {

        selectOptionAuthor.push({value: item.id, label: item.name})
    });
    let selectOptionKeyword = [];
    keywordName.forEach(item => {

        selectOptionKeyword.push({value: item.id, label: item.name})
    });


    return (
        <div>
            <div className={'container ' + style.form}>
                <h1 className="mt-4">Редактирование статьи</h1>

                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">


                    <div className="col-2">
                        <NavLink to={"/admin/allArticles"}><Button variant="dark">Список статей</Button></NavLink>
                    </div>
                    <div className="col-2">
                        <NavLink to={"/admin/addArticle"}><Button variant="success">Добавить
                            статью</Button></NavLink>
                    </div>
                    <div className="col-4"><NavLink to={"/admin/addAuthors"}><Button variant="secondary">Добавить автора
                        / список</Button></NavLink></div>
                    <div className="col-4"><NavLink to={"/admin/addKeywords"}><Button variant="secondary">Добавить ключевые слова (хештэг)</Button></NavLink></div>
                </div>
                <br/>
                <h2> <br/> <span className='red'>{name}</span></h2>
                <h6 className='text-secondary'>Пустые поля останутся без изменений</h6>
                <hr/>
                <label>Название</label>
                <input type="text" className="form-control"
                       value={name}
                       onChange={(e) => setName(e.target.value)}
                       placeholder="Название"/> <br/>
                <label>Аннотация</label>
                <textarea onChange={(e) => setDescription(e.target.value)} value={description}></textarea>


                <label>udk</label>
                <input type="text" required="required" className="form-control"
                       onChange={(e) => setUdk(e.target.value)}
                       value={udk}
                       placeholder="udk"/> <br/>

                <label>Журнал</label><br/>
                <select onChange={onChangeJournal}  className="form-control">
                    <option value={0}/>
                    {
                        journalName.map((item) =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                        )
                    }
                </select> <br/>
                <label>Релиз журнала</label><br/>
                <select onChange={onChangeReleases}  className="form-control">
                    <option value={0}/>
                    {
                        releasesName.map((item) =>
                            <option key={item.id} value={item.id}>Номер {item.number}  ///  Том {item.tome}  ///  ISSN {item.issn}</option>
                        )
                    }
                </select> <br/>


                <label>Авторы</label>
                <Select
                    onChange={(selectedOption) => {
                        setAuthor(selectedOption);
                    }}
                    value={author}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={selectOptionAuthor}
                    placeholder='выберите авторов'
                /><br/>
                <label>Ключевые слова</label>
                <Select
                    onChange={(selectedOption) => {
                        setKeyword(selectedOption);
                    }}
                    value={keyword}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={selectOptionKeyword}
                    placeholder='выберите ключевые слова'
                /><br/>
                <label>Документ для загрузки</label>
                <input type="file" className="form-control"
                       onChange={(e) => setFile(e.target.files[0])}

                       placeholder="file"/> <br/>
                <button onClick={addArticle} className="btn btn-primary">Сохранить изменения</button>
                { loader ? <Preloader/> : '' }
                <p className='red'>{error}</p>
            </div>
        </div>
    )
}

export default withRouter(EditArticle);