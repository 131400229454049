import React, {useEffect, useState} from 'react';
import {NavLink, Redirect} from "react-router-dom";
import {Button, Table} from "react-bootstrap";
import * as axios from "axios";
import {baseURL} from "../../../api/api";

export const AllReleases = () => {


    const [data, setData] = useState([[]]);
    const [name, setName] = useState("");
    const [journalName, setJournalName] = useState([]);

    useEffect(() => {
        getJournalName();
    }, []);

    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user-info');

    async function deleteOperation(id) {
        await fetch(baseURL+"api/releasedelete/" + id, {
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + token
            }

        });

        window.location.reload();
    }
    function getJournalName() {
        axios.get(baseURL+'api/journals')
            .then(response => {
                setJournalName(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }
    function onChangeJournal(e){

        axios.get(baseURL+'api/release/'+e.target.value)
            .then(response => {
                setData(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error))
    }


    return (
        <>
            <div className='container'>
                <h1 className='mb-5  mt-4'>Список выпусков журналов</h1>
                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-6">
                        <NavLink to={"/admin/alljournals"}><Button variant="dark">Список журналов / Создать новый журнал</Button></NavLink>
                    </div>

                    <div className="col-3"><NavLink to={"/admin/allreleases"}><Button disabled variant="dark">Список выпусков журналов</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addreleases"}><Button variant="dark">Добавить новый выпуск </Button></NavLink></div>

                </div>

            </div>
            <div className="col-lg-12">
                <div className="container">
                    <label>Выберите журнал:</label><br/>
                    <select onChange={onChangeJournal}  className="form-control">
                        <option value={0}/>
                        {
                            journalName.map((item) =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                        }
                    </select> <br/>
                </div>


                <h2 className='mb-5  mt-4 text-center'>Список выпусков журналов</h2>


                <Table>

                    <thead>
                    <tr>
                        <td>Дата</td>
                        <td>Номер</td>
                        <td>issn</td>
                        <td>Том</td>
                        <td>Изменить</td>
                        <td><span className="red">УДАЛИТЬ</span></td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item) =>
                            <tr key={item.id}>
                                <td>{item.date && item.date.slice(0,4)}</td>
                                <td>{item.number}</td>
                                <td>{item.issn}</td>
                                <td>{item.tome}</td>
                                <td>
                                    <NavLink to={"editreleases/" + item.id}>
                                        <span className="text-secondary">Изменить </span>
                                    </NavLink>
                                    <br/>


                                </td>
                                <td>
                                    <button onClick={() => deleteOperation(item.id)}
                                            className="btn btn-outline-danger">Удалить
                                    </button>
                                </td>

                            </tr>
                        )

                    }
                    </tbody>
                </Table>
            </div>
        </>
    )


}