import React, {useEffect, useState} from 'react';
import style from './Education.module.css';
import {Container, Row} from "react-bootstrap";
import {NavLink, withRouter} from "react-router-dom";
import * as axios from "axios";
import {baseURL} from "../../api/api";

const Department = (props) => {
    const [department, setDepartment] = useState([]);
    const [institute, setInstitute] = useState([]);

    useEffect(() => {
        getsetDepartmentName();
    }, []);

    function getsetDepartmentName() {
        axios.get(baseURL+'api/department/'+props.match.params.id)
            .then(response => {
                setDepartment(response.data.items);
                setInstitute(response.data.institute);

            })
            .catch(error => console.error(error))
    }

    return (
        <div className={style.contDepart}>
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>
                            <NavLink to={'/'}>
                            <div className={'breadcrumbItem'}>Учебно-методические пособия</div>
                            </NavLink>
                            <NavLink to={'/учебныеиздания/институты'}>
                                <div className={'breadcrumbItem'}>По институтам</div>
                            </NavLink>
                            <div className={'breadcrumbItem'}>{institute.name}</div>
                        </div>
                    </Row>
                </Container>
            </div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h1>Статьи</h1>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li ><NavLink to={'/учебныеиздания'}><p>По дате публикации</p></NavLink></li>
                                    <li ><NavLink to={"/учебныеиздания/авторы"}><p>По авторам</p></NavLink></li>
                                    <li className={style.current}><NavLink to={"/учебныеиздания/институты"}><p>По институтам</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        {department.length?
                            department.map(item=>(
                                <div key={item.id} className={style.DepItem + " row mt-3"}>

                                    <div className={style.card + " card col-12 "}>
                                        <NavLink className='p-4' to={'/учебныеиздания/кафедра/'+item.id}>{item.name}</NavLink>
                                    </div>

                                </div>
                            )):null

                        }
                    </div>
                </div>

            </div>
        </div>
    )
}
export default withRouter(Department) ;