import style from './Education.module.css';
import {NavLink} from "react-router-dom";
import EducationItem from "./EducationItem";
import React from "react";
import Paginator from "../common/Paginator/Paginator";



const Education = (props) => {

    return (
        <div className={style.contInst}>




                        <h3 className='mb-4'>Всего учебно-методических пособий: {props.totalCount} </h3>
                        <Paginator currentPage={props.currentPage} onPageChanged={props.onPageChanged}
                                   totalItemsCount={props.totalCount} pageSize={props.pageSize}/>
                        <div>
                            {Array.isArray(props.articles)?
                                props.articles.map(u =>(

                                    <EducationItem
                                        article={u}
                                        key={u.id}/>

                                )):null
                            }
                        </div>




        </div>
    )
}
export default Education;