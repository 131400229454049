import {useEffect, useState} from 'react'
import React from "react";
import * as axios from "axios";
import {NavLink, Redirect} from "react-router-dom";
import style from "./form.module.css";
import {Button, Table} from "react-bootstrap";
import {baseURL} from "../../../api/api";


function AddKeywords() {

    const [dataKeywords, setDataKeywords] = useState([[], []]);
    const [dataKeywordsList, setDataKeywordsList] = useState([[], []]);

    const [name, setName] = useState("");

    useEffect(() => {
        getDataKeywords();
    }, []);

    useEffect(() => {
        if(name){
            setDataKeywordsList(dataKeywords?.filter((a)=> a?.name?.toLowerCase()?.includes(name?.toLowerCase()?.trim())))
        }else{
            setDataKeywordsList(dataKeywords)
        }

    }, [dataKeywords,name]);

    let token = localStorage.getItem('token');

    function getDataKeywords() {
        axios.get(baseURL+'api/keywords')
            .then(response => {
                setDataKeywords(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }

    async function deleteOperation(id) {

        axios({
            method: 'DELETE',
            url: baseURL+"api/keyworddelete/" + id,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            getDataKeywords();
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function addKeyword() {
        if(dataKeywords.filter((a)=> a?.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase())?.length == 0 ){
        const formData = new FormData();
        formData.append('name', name);

        axios({
            method: 'post',
            url: baseURL+'api/keywordadd',
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            console.log('Ответ сервера успешно получен!');
            console.log(response.data);
            alert("Хештэг добавлен");

            window.location.reload();
        })
            .catch(function (error) {
                console.log(error);
            });

        }else{
            alert('Такой хештэг уже есть')
        }
    }

    return (
        <div>
            <div className={'container ' + style.form}>
                <h1 className="mt-4">Добавить ключевое слово</h1>

                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">


                    <div className="col-2">
                        <NavLink to={"/admin/allArticles"}><Button variant="dark">Список статей</Button></NavLink>
                    </div>
                    <div className="col-2">
                        <NavLink to={"/admin/addArticle"}><Button variant="success">Добавить
                            статью</Button></NavLink>
                    </div>
                    <div className="col-4"><NavLink to={"/admin/addAuthors"}><Button variant="secondary">Добавить автора
                        / список</Button></NavLink></div>
                    <div className="col-4"><NavLink to={"/admin/addKeywords"}><Button variant="secondary">Добавить ключевые слова (хештэг)</Button></NavLink></div>
                </div>
                <div className="col-lg-12">
                    <hr/>
                    <label>Введите новое ключевое слово (хештэг)</label><br/>
                    <input type="text" className="form-control"
                           onChange={(e) => setName(e.target.value)}
                           placeholder="хештэг"/>
                    <button onClick={addKeyword} disabled={name?.length === 0}  className="btn btn-primary">Добавить </button>
                    <hr/>
                    <Table>

                        <thead>
                        <tr>
                            <td>ID</td>
                            <td>NAME</td>
                            <td><span className="red">DELETE</span></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dataKeywordsList.map((item) =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>

                                    <td>
                                        <button
                                            onClick={() => deleteOperation(item.id)}
                                            className="btn btn-outline-danger">Delete
                                        </button>
                                    </td>

                                </tr>
                            )

                        }
                        </tbody>
                    </Table>
                </div>

            </div>
        </div>
    )
}

export default AddKeywords