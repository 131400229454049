import {articlesAPI} from "../api/api";

const SET_ARTICLE = 'SET_ARTICLE';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';



let initialState = {
    article: {
        item: {
            keywords:[],
            author:[],
            release_inf:[],
            journal:[]
        },
        otherArticles: []
    },
    isFetching: true
};

const articleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ARTICLE: {
            return {...state, article: action.article}
        }
        case TOGGLE_IS_FETCHING: {
            return {...state, isFetching: action.isFetching}
        }
        default:
            return state;
    }
}



export const setArticle = (article) => ({type: SET_ARTICLE, article});
export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, isFetching});

export const requestArticle = (articleID) =>{
    return async (dispatch) => {
        let clean = {
            item: {
                keywords:[],
                author:[],
                release_inf:[],
                journal:[]
            },
            otherArticles: []
        };
        dispatch(setArticle(clean));
        dispatch(toggleIsFetching(true));
        let data = await articlesAPI.getArticle(articleID);
        dispatch(toggleIsFetching(false));
        dispatch(setArticle(data));
    }
}

export default articleReducer
