import React from 'react';
import {Container, Row} from "react-bootstrap";
import style from "../Section.module.css";
import {NavLink} from "react-router-dom";

const Disertation = ({count}) =>{
    return (
        <div>
            <Container>
                <Row>
                    <h4 className={style.title}><i className="fas fa-newspaper"></i> Диссертации <sup>{count}</sup></h4>
                    <ul>
                        <NavLink to={'/диссертации/направления'}><li>По направлениям</li></NavLink>
                        <NavLink to={'/диссертации/авторы'}><li>По авторам</li></NavLink>
                    </ul>

                </Row>
            </Container>
        </div>
    )
}
export default Disertation;