import React from 'react';
import style from './Keywords.module.css';
import {Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";


const Keywords = (props) => {
    return (
        <div className={style.cont}>
            <Container>
                <Row>
                    <div className={"col-sm-6 col-lg-9" + " " + style.title}>Ключевые слова</div>
                    <div className={"col-sm-6 col-lg-3" + " " + style.titleMin}><NavLink to={'/ключевыеслова'}>Все
                        ключевые слова &gt;</NavLink></div>

                </Row>


                <ul className={style.keywords + ' row'}>
                    {
                        props.props.map(item => (
                            <li key={item.id} className={'col-md-4 col-sm-6 col-12'}><NavLink to={'/хештег/' + item.id}>#<span
                                className={style.name}>{item.name}</span>
                                {/*<span className={style.count}>67</span>*/}
                            </NavLink></li>
                        ))
                    }
                </ul>


            </Container>

        </div>
    )
}
export default Keywords;
