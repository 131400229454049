import {useEffect, useState} from 'react'
import React from "react";
import * as axios from "axios";
import {NavLink, Redirect} from "react-router-dom";
import style from "./../Article/form.module.css";
import {Button, Table} from "react-bootstrap";
import {baseURL} from "../../../api/api";
import CategoriesEducation from "./CategoriesEducation";


function AddNapravlenieDisertation() {

    const [dataAuthors, setDataAuthors] = useState([]);
    const [dataKeywordsList, setDataKeywordsList] = useState([]);

    const [name, setName] = useState("");

    useEffect(() => {
        getDataAuthors();
    }, []);
    useEffect(() => {
        if(name){
            setDataKeywordsList(dataAuthors.filter((a)=> a.name.toLowerCase().includes(name.toLowerCase().trim())))
        }else{
            setDataKeywordsList(dataAuthors)
        }

    }, [dataAuthors,name]);

    let token = localStorage.getItem('token');

    function getDataAuthors() {
        axios.get(baseURL+'api/disertationnapravlenie')
            .then(response => {
                setDataAuthors(response.data.items);
                // console.log(response.data.items)
            })
            .catch(error => console.error(error));


    }

    async function deleteOperation(id) {

        axios({
            method: 'DELETE',
            url: baseURL+"api/deletenapravleniedisertation/" + id,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            getDataAuthors();
           
        })
            .catch(function (error) {
                console.log(error);
            });

    }
    async function updateOperation(id, name) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        axios({
            method: 'post',
            url: baseURL+'api/updatenapravleniedisertation'+"?_method=PUT",
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {
            getDataAuthors();
        })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function addAuthor() {
        if(dataAuthors.filter((a)=> a?.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase())?.length == 0 ){
        const formData = new FormData();
        formData.append('name', name);

        axios({
            method: 'post',
            url: baseURL+'api/addnapravleniedisertation',
            data: formData,
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(function (response) {

            alert("Направление добавлено");
            window.location.reload();
        })
            .catch(function (error) {
                console.log(error);
            });

        }else{
            alert('Такое направление уже есть')
        }
    }

    return (
        <div>
            <div className={'container ' + style.form}>
                <h1 className="mt-4 mb-5  ">Добавить направление</h1>

                <div className="row mb-4">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <NavLink to={"/admin"}><Button variant="primary">Главная</Button></NavLink>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-3">
                <div >
                        <NavLink to={"/admin/alldisertation"}><Button variant="secondary">Список диссертаций</Button></NavLink>
                    </div>
                </div>
                <div className="row mb-4">

                   
                    <div className="col-3">
                        <NavLink to={"/admin/adddisertation"}><Button variant="secondary">Добавить диссертацию</Button></NavLink>
                    </div>
                    <div className="col-3"><NavLink to={"/admin/addauthordisertation"}><Button variant="secondary">Авторы</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addnapravleniedisertation"}><Button variant="dark">Направления</Button></NavLink></div>
                    <div className="col-3"><NavLink to={"/admin/addspecdisertation"}><Button variant="secondary">Специальности</Button></NavLink></div>
                </div>
                <div className="col-lg-12">
                    <hr/>
                    <label>Введите направление</label><br/>
                    <input type="text" className="form-control"
                           onChange={(e) => setName(e.target.value)}
                           placeholder="направление"/>
                    <button onClick={addAuthor} disabled={name?.length === 0} className="btn btn-primary">Добавить направление</button>
                    <hr/>
                    <Table>

                        <thead>
                        <tr>
                            <td>ID</td>
                            <td>NAME</td>
                            <td>UPDATE</td>
                            <td><span className="red">DELETE</span></td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dataKeywordsList.map((item) =>
                                <CategoriesEducation key={item.id} item={item} updateOperation={updateOperation} deleteOperation={deleteOperation}/>
                            )
                        }
                        </tbody>
                    </Table>
                </div>

            </div>
        </div>
    )
}

export default AddNapravlenieDisertation