import { useCallback, useMemo, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

export const useUrlSearchParams = (init) => {
    const isInit = useRef(false);

    const history = useHistory();
    const location = history.location;
    const [params, _setParams] = useState(init);

    const search = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const updateSearch = useCallback(() => {
        history.push({ hash: location.hash, search: search.toString() });
    }, [history, location, search]);

    const setParams = useCallback(
        (params = {}) => {
            _setParams((prev) => ({ ...prev, ...params }));
            Object.entries(params).forEach(([key, value]) => {
                if (value === "" || value === null || value === undefined) {
                    search.delete(key);
                    return;
                }
                search.set(key, value);
            });
            updateSearch();
        },
        [search, updateSearch]
    );

    // без юз эффектов, чтобы параметры загружались в синхронном потоке
    if (!isInit.current) {
        const _params = Object.fromEntries(
            Object.entries(init).map(([key, value]) => [key, search.get(key) ?? value])
        );
        _setParams(_params);
        isInit.current = true;
        return [_params, setParams];
    }

    return [params, setParams];
};
