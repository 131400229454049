import { educationalAPI} from "../api/api";

const SET_EDUCATIONAL_DATE = 'SET_EDUCATIONAL_DATE';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_TOTAL_EDUCATION_COUNT = 'SET_TOTAL_EDUCATION_COUNT';
const SET_INSTITUTE = 'SET_INSTITUTE';
const SET_DEPARTMENT = 'SET_DEPARTMENT';



let initialState = {
    articles:{
        author:[],
    },
    isFetching: true,
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    department:{
        name:''
    },
    institute:{
        name:''
    }
};

const educationalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EDUCATIONAL_DATE: {
            return {...state, articles: action.articles}
        }
        case SET_CURRENT_PAGE: {
            return {...state, currentPage: action.currentPage}
        }
        case SET_TOTAL_EDUCATION_COUNT: {
            return {...state, totalCount: action.count}
        }
        case SET_INSTITUTE: {
            return {...state, institute: action.institute}
        }
        case SET_DEPARTMENT: {
            return {...state, department: action.department}
        }
        case TOGGLE_IS_FETCHING: {
            return {...state, isFetching: action.isFetching}
        }
        default:
            return state;
    }
}



export const setEducationalDate = (articles) => ({type: SET_EDUCATIONAL_DATE, articles});
export const setDepartment = (department) => ({type: SET_DEPARTMENT, department: department});
export const setInstitute = (institute) => ({type: SET_INSTITUTE, institute: institute});
export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, isFetching});
export const setCurrentPage = (currentPage) => ({type: SET_CURRENT_PAGE, currentPage})
export const setTotalCount = (setTotalCount) => ({type: SET_TOTAL_EDUCATION_COUNT, count: setTotalCount})


export const requestEducation = (page, pageSize) =>{
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        dispatch(setCurrentPage(page));
        let data = await educationalAPI.getEducationalDate(page, pageSize);
        dispatch(toggleIsFetching(false));
        dispatch(setEducationalDate(data.items));
        dispatch(setTotalCount(data.totalCount));
    }
}
export const requestEducationDepart = (page, pageSize,id) =>{
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        dispatch(setCurrentPage(page));
        let data = await educationalAPI.getEducationalDepartment(page, pageSize,id);
        dispatch(toggleIsFetching(false));
        dispatch(setEducationalDate(data.items));
        dispatch(setInstitute(data.institute));
        dispatch(setDepartment(data.department));
        dispatch(setTotalCount(data.totalCount));

    }
}
export default educationalReducer
