import React, {useEffect, useState} from 'react';
import * as axios from "axios";
import {NavLink} from "react-router-dom";
import {baseURL} from "../../api/api";
import style from "./Disertation.module.css";
import {Container, Row} from "react-bootstrap";

const DisertationAuthors = () => {

    const [authors, setAuthors] = useState([]);
    const [authorsList, setAuthorsList] = useState([]);
    const [name, setName] = useState('')



    useEffect(() => {
        if(name){
            setAuthorsList(authors.filter(item=> item.name.toUpperCase().includes(name.toUpperCase())))
        }else{
            setAuthorsList(authors);
        }
    }, [name,authors]);

    useEffect(() => {
        getsetAuthors()
    }, []);

    function getsetAuthors() {
        axios.get(baseURL+'api/disertationauthor')
            .then(response => {
                setAuthors(response.data.items);
            })
            .catch(error => console.error(error))
    }

    return (

        <div >
            <div className={'breadcrumdBottom'}>
                <Container className=''>
                    <Row>
                        <div className={'breadcrumdCont'}>
                            <NavLink to={'/'}>
                                <div className={'breadcrumbTitle'}>eLibrary</div>
                            </NavLink>


                            <NavLink to={'/учебныеиздания'}>
                                <div className={'breadcrumbItem'}>Диссертации</div>
                            </NavLink>


                            <div className={'breadcrumbItem'}>По авторам</div>


                        </div>
                    </Row>
                </Container>
            </div>
            <div className={style.Authcont + ' container mt-4 ' + style.contInst}>
                <div className='row'>
                    <div className="col-12 col-md-4">
                        <div className={style.educont}>
                            <div className={style.edutitle}>
                                <h2>Диссертации</h2>
                            </div>
                            <div className={style.edunav}>
                                <ul>
                                    <li className={style.current}><NavLink to={"/диссертации/авторы"}><p>По авторам</p></NavLink></li>
                                    <li><NavLink to={"/диссертации/направления"}><p>По направлениям</p></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <h1>Поиск диссертации по автору</h1>
                        <div className='w-100'>
                            <ul className="row">
                                <input
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                    placeholder='Поиск автора'
                                    type="text"
                                    className='form-control mb-3 ml-3'/>
                                {authorsList ?
                                    authorsList.map(item=>(
                                        <li key={item.id} className="col-6 dark"><NavLink to={"/диссертации/автор/"+item.id}><i
                                            className="fas fa-user-alt"></i> {item.name}</NavLink>
                                        </li>
                                    )):null

                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisertationAuthors;