import {articlesAPI} from "../api/api";

const SET_RELEASES = 'SET_RELEASES';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';



let initialState = {
    releases: {
        'items': [],
        'journal': ''
    },
    isFetching: true
};

const releasesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RELEASES: {
            return {...state, releases: action.releases}
        }
        case TOGGLE_IS_FETCHING: {
            return {...state, isFetching: action.isFetching}
        }
        default:
            return state;
    }
}



export const setReleases = (releases) => ({type: SET_RELEASES, releases});
export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, isFetching});

export const requestReleases = (id) =>{
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        let data = await articlesAPI.getReleases(id);
        dispatch(toggleIsFetching(false));
        dispatch(setReleases(data));
    }
}

export default releasesReducer
