import React from 'react';
import {connect} from 'react-redux';
import {requestArticle} from '../../../redux/article-reducer';
import Preloader from "../../common/Preloader/Preloader";
import Article from "./Article";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import style from './Article.module.css';


class ArticleContainer extends React.Component {


    refreshProfile() {
        let articleId = this.props.match.params.id;
        if (!articleId) {
            articleId = 1;
        }
        this.props.requestArticle(articleId);
    }

    componentDidMount() {
        this.refreshProfile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id != prevProps.match.params.id ) {
            this.refreshProfile();
        }
    }

    render() {
        return <>
            <div className={style.preload}>{this.props.isFetching ? <Preloader/> : null}</div>
            <Article
                id={this.props.match.params.id}
                article={this.props.article}
            />

        </>
    }
}

let mapStateToProps = (state) => {
    return {
        article:  state.articlePage.article,
        isFetching: state.articlePage.isFetching
    }
}


// export default connect(mapStateToProps, {requestArticle})(ArticleContainer)
export default  compose(connect(mapStateToProps, {requestArticle}), withRouter)(ArticleContainer)